import { useEffect, useState } from 'react';
import {
  Button,
  Form,
  Row,
  InputNumber,
  Modal,
  Col,
  Checkbox,
  Input,
  message as antdMessage
} from 'antd';
import PropTypes from 'prop-types';
import { handleDivideLot } from './utils/handleDivideLot';

/**
 * DivisionLotModal Component
 *
 * This component displays a modal for dividing lots in the charging grid.
 *
 * @component
 * @param {object} props - The props for the component.
 * @param {boolean} props.isDivisionLotModalOpen - Flag indicating if the modal is open.
 * @param {function} props.setIsDivisionLotModalOpen - Function to set the modal open state.
 * @param {array} props.selectedRowKeys - The selected row keys in the table.
 * @param {function} props.setSelectedRowKeys - Function to set the selected row keys.
 * @param {array} props.dataSource - The data source for the table.
 * @param {function} props.t - i18n translation function.
 * @param {function} props.dispatchAPI - Function for making API calls.
 * @param {object} props.chargingGrid - The charging grid data.
 * @param {string} props.chargingGrid._id - The ID of the charging grid.
 * @param {function} props.message - Function for displaying messages.
 * @param {function} props.setChargingGrid - Function to set the charging grid data.
 * @returns {JSX.Element} React component
 */

const { TextArea } = Input;

export const DivisionLotModal = ({
  isDivisionLotModalOpen,
  setIsDivisionLotModalOpen,
  selectedRowKeys,
  setSelectedRowKeys,
  dataSource,
  t,
  dispatchAPI,
  chargingGrid,
  message,
  setChargingGrid,
  collectiveOwnership,
  setCollectiveOwnership
}) => {
  const [lotNumber, setLotNumber] = useState([]);
  const [maxLotNumber, setMaxLotNumber] = useState([]);

  const getLatestLotNumber = () => {
    const lotNumbers = dataSource
      .filter(
        (obj) =>
          obj.Lot.value !== 'Voulu' &&
          obj.Lot.value !== 'Total' &&
          typeof obj.Lot.value !== 'string'
      )
      .map((objet) => objet.Lot.value);

    setMaxLotNumber(Math.max(...lotNumbers));
  };

  useEffect(() => {
    getLatestLotNumber();
  }, [isDivisionLotModalOpen]);

  const handleLotNumberChange = (e) => {
    const lotNumbers = [];
    for (let i = 0; i < Number(e); i += 1) {
      lotNumbers.push(i);
    }

    setLotNumber(lotNumbers);
  };

  return (
    <Modal
      title={t('charging-grids.show.modals.title.divide')}
      open={isDivisionLotModalOpen}
      onCancel={() => {
        setIsDivisionLotModalOpen(false);
        setSelectedRowKeys([]);
      }}
      footer={null}
    >
      <Form
        onFinish={(body) =>
          handleDivideLot({
            body,
            dataSource,
            antdMessage,
            t,
            setSelectedRowKeys,
            selectedRowKeys,
            dispatchAPI,
            chargingGrid,
            setChargingGrid,
            collectiveOwnership,
            setCollectiveOwnership,
            lotNumber,
            message,
            setIsDivisionLotModalOpen
          })
        }
      >
        <Form.Item
          name={['number']}
          label={t('charging-grids.show.modals.label.number_of_lot')}
        >
          <InputNumber onChange={(e) => handleLotNumberChange(e)} />
        </Form.Item>
        {lotNumber.length
          ? lotNumber.map((number, index) => (
              <Row justify="space-between">
                <Col>
                  <Form.Item
                    initialValue={maxLotNumber + index + 1}
                    name={['lots', number]}
                    label={t('charging-grids.show.modals.label.lot_number')}
                  >
                    <InputNumber />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name={[number, 'is_duplicate_lot']}
                    valuePropName="checked"
                  >
                    <Checkbox>
                      {t('charging-grids.show.modals.label.is_duplicate_lot')}
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            ))
          : null}
        <Form.Item>
          <Form.Item
            name={['comment']}
            label={t('charging-grids.show.modals.label.comment')}
          >
            <TextArea />
          </Form.Item>

          <Row justify="end" gutter={[6, 6]}>
            <Button
              type="cancel"
              onClick={() => {
                setIsDivisionLotModalOpen(false);
                setSelectedRowKeys([]);
              }}
            >
              {`${t('buttons.cancel')} `}
            </Button>
            <Button type="primary" htmlType="submit">
              {`${t('buttons.save')} `}
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

DivisionLotModal.propTypes = {
  isDivisionLotModalOpen: PropTypes.bool.isRequired,
  setIsDivisionLotModalOpen: PropTypes.func.isRequired,
  selectedRowKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedRowKeys: PropTypes.func.isRequired,
  dataSource: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  t: PropTypes.func.isRequired,
  dispatchAPI: PropTypes.func.isRequired,
  chargingGrid: PropTypes.shape({
    _id: PropTypes.string
  }),
  message: PropTypes.func.isRequired,
  setChargingGrid: PropTypes.func.isRequired,
  collectiveOwnership: PropTypes.shape({}),
  setCollectiveOwnership: PropTypes.func.isRequired
};

DivisionLotModal.defaultProps = {
  chargingGrid: {},
  collectiveOwnership: {}
};
