/**
 * Handles the editing of a record in the table.
 * @function
 * @param {Object} params - Function parameters
 * @param {Object} params.record - The record to be edited
 * @param {FormInstance} params.tableForm - The form instance for the table
 * @param {Function} params.setEditKey - Function for setting the edit key
 * @returns {void}
 */

export const handleEdit = ({ record, tableForm, setEditKey }) => {
  setEditKey(record.key);

  const transformedRecord = {};

  Object.keys(record).forEach((key) => {
    const value = record[key];

    if (typeof value === 'object' && !value.value) {
      value.value = null;
    }

    if (typeof value === 'object' && 'value' in value) {
      transformedRecord[key] = value.value;
    } else {
      transformedRecord[key] = value;
    }
  });

  tableForm.setFieldsValue({ ...transformedRecord });
};
