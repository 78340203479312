import { useEffect, useState } from 'react';
import { Layout, Row, Card, Spin, Button, Typography, Col } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import CreateTemplateDrawer from './CreateTemplateDrawer';
import { useDownloadDocument } from '../../../utils/downloadDoc';

const { Content } = Layout;

const ListTemplate = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { downloadDocument } = useDownloadDocument();
  const [templates, setTemplates] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [refreshData, setRefreshData] = useState(false);

  const getTemplates = async () => {
    try {
      setIsLoading(true);
      const { data } = await dispatchAPI('GET', {
        url: '/templates?type=charging_grid'
      });
      setTemplates(data);
      setIsLoading(false);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getTemplates();
    })();
  }, [refreshData]);

  return (
    <Content
      className="site-layout-background"
      style={{
        marginTop: 2,
        padding: 24,
        backgroundColor: '#fff'
      }}
    >
      <Spin spinning={isLoading}>
        <Card>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '10px'
            }}
          >
            <Button type="primary" onClick={() => setVisible(true)}>
              {t('breadcrumbs.create')}
            </Button>
          </div>
          {templates &&
            templates.map((template) => (
              <div>
                <Row
                  align="middle"
                  justify="space-around"
                  className="settings-template-row"
                >
                  <Col span={12}>
                    <Typography>{template.label}</Typography>
                  </Col>
                  <Col span={12}>
                    <Button
                      onClick={() =>
                        downloadDocument({
                          _id: template._id,
                          metadata: template.metadata,
                          contenType: template.contentType
                        })
                      }
                      type="link"
                    >
                      <DownloadOutlined style={{ fontSize: 18 }} />
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}
        </Card>
      </Spin>
      <CreateTemplateDrawer
        setRefreshData={setRefreshData}
        refresh={refreshData}
        visible={visible}
        setVisible={setVisible}
      />
    </Content>
  );
};

export default ListTemplate;
