import { useState } from 'react';
import { Row, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { ListResource } from '../../components';
import { useColumns } from './columns';

/**
 * ListCollectiveOwnerships Component
 *
 * This component is responsible for listing collective ownerships.
 *
 * @component
 * @returns {JSX.Element} React component
 */

export const ListCollectiveOwnerships = () => {
  const { t } = useTranslation();
  const columns = useColumns();
  const [isArchived, setIsArchived] = useState(false);

  return (
    <ListResource
      resourceName="collective-ownerships"
      columns={columns}
      resourceModelName="Collective-ownership"
      extraButtons={
        <Row align="middle" className="list-switch-wrapper">
          <Switch onChange={() => setIsArchived(!isArchived)} />
          <p>{t('collective-ownerships.archived')}</p>
        </Row>
      }
      extraQuery={isArchived ? 'is_archived=true' : 'is_archived=false'}
    />
  );
};
