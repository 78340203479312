import { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button, Popconfirm, Skeleton, Row, Col, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { useListContent } from './listContent';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../components';
import { routes } from '../../utils/constants/adminRoutes';
import ChargingGrid from '../charging-grid/ChargingGrid';

/**
 * ShowCollectiveOwnership Component
 *
 * This component displays the details of a collective ownership entity.
 *
 * @component
 * @returns {JSX.Element} React component
 */

export const ShowCollectiveOwnership = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [collectiveOwnership, setCollectiveOwnership] = useState({});
  const listContent = useListContent(collectiveOwnership);
  const [chargingGrid, setChargingGrid] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState([]);

  const getCollectiveOwnership = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/collective-ownership/${id}?populate=charging_grid,charging_grid.columns.column`
      });
      setCollectiveOwnership(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getCollectiveOwnership();
    })();
  }, [getCollectiveOwnership]);

  const deleteCollectiveOwnership = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/collective-ownership/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={t('collective-ownerships.show.title', {
          name: collectiveOwnership?.name
        })}
        extra={
          <>
            <Link
              to={{ pathname: `${routes.COLLECTIVE_OWNERSHIP}/edit/${id}` }}
            >
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteCollectiveOwnership}
              icon={<WarningOutlined />}
            >
              <Button type="link" danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentCustom>
        <Row>
          <Col xs={24}>
            <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
              <DescriptionList data={listContent} translate />
            </Skeleton>
          </Col>
          <Divider />
          <Col xs={24}>
            <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
              <ChargingGrid
                collectiveOwnership={collectiveOwnership}
                setCollectiveOwnership={setCollectiveOwnership}
                isLoading={isLoading}
                columns={columns}
                setColumns={setColumns}
                chargingGrid={chargingGrid}
                setChargingGrid={setChargingGrid}
                dataSource={dataSource}
                setDataSource={setDataSource}
              />
            </Skeleton>
          </Col>
        </Row>
      </ContentCustom>
    </>
  );
};
