import {
  isSameDay,
  eachDayOfInterval,
  getDay,
  isSameWeek,
} from 'date-fns';
import {
  WeekObject,
  GenericEvent,
  DayName
} from '../types';

export const daysToWeekObject = <T extends GenericEvent>(
  events: T[],
  startWeek: Date
) => {
  const dayNames: DayName[] = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday'
  ];

  const weekObject: WeekObject<T> = {
    sunday: [],
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: []
  };

  if (events == null) {
    return weekObject;
  }

  for (const googleEventIndex in events) {
    const eventStartTimeDay = events[googleEventIndex].startTime;
    const eventEndTimeDay = events[googleEventIndex].endTime;

    if (!isSameDay(eventStartTimeDay, eventEndTimeDay)) {
      const result = eachDayOfInterval({
        start: eventStartTimeDay,
        end: eventEndTimeDay
      });
      for (const dayInterval in result) {
        const splitedEvent = { ...events[googleEventIndex] };
        splitedEvent.startTime = result[dayInterval];
        splitedEvent.endTime = result[dayInterval];
        const weekObjectKey: DayName =
          dayNames[getDay(new Date(result[dayInterval]))];
        isSameWeek(startWeek, splitedEvent.startTime) &&
          weekObject[weekObjectKey].push(splitedEvent);
      }
    } else {
      const weekObjectKey: DayName = dayNames[getDay(eventStartTimeDay)];
      weekObject[weekObjectKey].push(events[googleEventIndex]);
    }
  }
  return weekObject;
};
