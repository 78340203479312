import { Row, Col, Typography, Button, Popconfirm, Input, Form } from 'antd';
import { DeleteOutlined, CopyOutlined, EditOutlined } from '@ant-design/icons';
import { handleDeleteColumn } from './handleDeleteColumn';
import { handleUpdateColumnName } from './handleUpdateColumnName';

const { Text } = Typography;

/**
 * Generates the title component for a column in the charging grid.
 * @function
 * @param {Object} params - Function parameters
 * @param {Object} params.columnObject - The column object containing column information
 * @param {Object} params.charging_grid - The charging grid object
 * @param {Function} params.dispatchAPI - Function for dispatching API requests
 * @param {Function} params.message - Function for displaying messages
 * @param {Function} params.t - Translation function
 * @param {Function} params.setChargingGrid - Function for setting charging grid data
 * @param {Function} params.setIsColumnModalOpen - Function for setting the column modal open state
 * @param {Function} params.setDuplicateColumn - Function for setting the duplicated column name
 * @param {Function} params.setIsFinalVersion - Function for setting the final version state
 * @returns {JSX.Element} - The generated title component
 */

export const generateColumnTitle = ({
  columnObject,
  charging_grid,
  dispatchAPI,
  message,
  t,
  setChargingGrid,
  setIsColumnModalOpen,
  setDuplicateColumn,
  setIsFinalVersion,
  collectiveOwnership,
  setCollectiveOwnership,
  setIsEditingColumnName,
  isEditingColumnName
}) => (
  <Row gutter={[2, 2]} align="middle" justify="space-between">
    <Col>
      {isEditingColumnName === columnObject.column.name ? (
        <Form>
          <Form.Item
            name={columnObject.column.name}
            initialValue={columnObject.column.name}
          >
            <Input
              autoFocus
              onPressEnter={(element) =>
                handleUpdateColumnName({
                  columnId: columnObject.column._id,
                  charging_grid,
                  dispatchAPI,
                  message,
                  setChargingGrid,
                  setIsFinalVersion,
                  collectiveOwnership,
                  setCollectiveOwnership,
                  value: element.target.value,
                  setIsEditingColumnName
                })
              }
            />
          </Form.Item>
        </Form>
      ) : (
        <Text>{columnObject.column.name}</Text>
      )}
    </Col>
    {!['Lot', 'Remarques'].includes(columnObject.column.name) &&
    !columnObject.delete_column ? (
      <Row gutter={[2, 2]}>
        <Col>
          <Button
            type="link"
            onClick={() => setIsEditingColumnName(columnObject.column.name)}
          >
            <EditOutlined />
          </Button>
        </Col>
        <Col>
          <Popconfirm
            title={t('charging-grids.show.messages.confirm_column_deletion')}
            onConfirm={() =>
              handleDeleteColumn({
                columnId: columnObject.column._id,
                charging_grid,
                dispatchAPI,
                message,
                setChargingGrid,
                setIsFinalVersion,
                collectiveOwnership,
                setCollectiveOwnership
              })
            }
          >
            <Button type="link" danger>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Col>
        <Col>
          <Button
            type="link"
            onClick={() => {
              setDuplicateColumn(columnObject.column.name);
              setIsColumnModalOpen(true);
            }}
          >
            <CopyOutlined />
          </Button>
        </Col>
      </Row>
    ) : null}
  </Row>
);
