/**
 * Changes the version of a charging grid.
 * @async
 * @function
 * @param {Object} params - Function parameters
 * @param {Function} params.dispatchAPI - Function for dispatching API requests
 * @param {Object} params.chargingGrid - The charging grid object
 * @param {boolean} params.isFinalVersion - Indicates if it's the final version
 * @param {Function} params.setChargingGrid - Function for setting charging grid data
 * @param {Function} params.message - Function for displaying messages
 * @throws {Error} Throws an error if the API request fails
 * @returns {Promise<void>} - A promise that resolves after the version change
 */

export const changeVersion = async ({
  dispatchAPI,
  chargingGrid,
  isFinalVersion,
  setChargingGrid,
  message
}) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: `/charging-grids/${chargingGrid._id}?is_final_version=${isFinalVersion}`
    });
    setChargingGrid(data);
  } catch (e) {
    message(e);
  }
};
