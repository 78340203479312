import PropTypes from 'prop-types';
import { useFields } from './fields';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';

/**
 * CreateUpdateCollectiveOwnership Component
 *
 * This component is responsible for creating or updating a collective ownership entity.
 *
 * @component
 * @param {Object} props - The component props
 * @param {string} props.purpose - The purpose of the component (e.g., 'create' or 'update')
 * @returns {JSX.Element} React component
 */

export const CreateUpdateCollectiveOwnership = ({ purpose }) => {
  const { fields } = useFields(purpose);

  return (
    <CreateUpdateContainer
      fields={fields}
      isFieldsLoading={false}
      purpose={purpose}
      baseUrl="collective-ownerships"
      resource="collective-ownerships"
      withFilesManager={false}
      withEnums={false}
    />
  );
};

CreateUpdateCollectiveOwnership.propTypes = {
  purpose: PropTypes.string.isRequired
};
