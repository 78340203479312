import {
  HomeOutlined,
  UserOutlined,
  BranchesOutlined,
  SettingOutlined
} from '@ant-design/icons';
import { Building } from '@carbon/icons-react';

const navMenuLogos = {
  HOME: <HomeOutlined />,
  USERS: <UserOutlined />,
  COLLECTIVE_OWNERSHIP: <Building className="anticon" />,
  WORKFLOWS: <BranchesOutlined />,
  CONFIGURATIONS: <SettingOutlined />
};

export default navMenuLogos;
