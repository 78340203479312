import { Row, Col, Typography, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useDownloadDocument } from '../../../utils/downloadDoc';
import { ExportExcel } from '../../../components/ExportExcelButton/ExportExcelButton';
import { generateFile } from '../utils/generateFile';

const { Text } = Typography;

export const ExportButtons = ({
  chargingGrid,
  columns,
  isFinalVersion,
  dispatchAPI,
  message,
  collectiveOwnership,
  setChargingGrid,
  setIsVersionButton,
  dataSource,
  template,
  t,
  setIsLabelModalOpen,
  setIsEditLabel
}) => {
  const { downloadDocument } = useDownloadDocument();

  return (
    <Col xs={24} xxl={24}>
      <Row
        gutter={[16, 16]}
        justify="space-between"
        align="middle"
        className="grid-header"
      >
        <Col>
          <Row gutter={[16, 16]} align="middle">
            <Col className="grid-title">
              <Text>{chargingGrid.label}</Text>
            </Col>
            <Col className="grid-date">
              <Row align="middle">
                <Col>
                  <Text>
                    {dayjs(chargingGrid.document_date).format('DD/MM/YYYY')}
                  </Text>
                </Col>
                <Col>
                  <Button
                    type="link"
                    onClick={() => {
                      setIsLabelModalOpen(true);
                      setIsEditLabel(true);
                    }}
                  >
                    <EditOutlined />
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col>
          {columns.length && dataSource.length ? (
            <Row gutter={[6, 6]}>
              <Col>
                <ExportExcel
                  columns={columns}
                  exportDatas={dataSource}
                  chargingGrid={chargingGrid}
                />
              </Col>
              <Col>
                <Button
                  disabled
                  onClick={() =>
                    generateFile({
                      isFinalVersion,
                      chargingGrid,
                      dispatchAPI,
                      message,
                      collectiveOwnership,
                      setChargingGrid,
                      setIsVersionButton,
                      template,
                      downloadDocument
                    })
                  }
                >
                  {t('charging-grids.show.buttons.export_pdf')}
                </Button>
              </Col>
            </Row>
          ) : null}
        </Col>
      </Row>
    </Col>
  );
};

ExportButtons.propTypes = {
  chargingGrid: PropTypes.shape({
    label: PropTypes.string,
    document_date: PropTypes.string
  }),
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  isFinalVersion: PropTypes.bool,
  dispatchAPI: PropTypes.func,
  message: PropTypes.func,
  collectiveOwnership: PropTypes.shape({}),
  setChargingGrid: PropTypes.func,
  setIsVersionButton: PropTypes.func,
  dataSource: PropTypes.arrayOf(PropTypes.shape({})),
  template: PropTypes.arrayOf(PropTypes.shape({})),
  t: PropTypes.func,
  setIsLabelModalOpen: PropTypes.func,
  setIsEditLabel: PropTypes.func
};

ExportButtons.defaultProps = {
  chargingGrid: {
    label: '',
    document_date: ''
  },
  columns: [],
  isFinalVersion: false,
  dispatchAPI: () => {},
  message: () => {},
  collectiveOwnership: {},
  setChargingGrid: () => {},
  setIsVersionButton: () => {},
  dataSource: [],
  template: '',
  t: () => {},
  setIsLabelModalOpen: () => {},
  setIsEditLabel: () => {}
};
