// Main nav menuItems
export const routes = {
  HOME: '/',
  USERS: '/users',
  COLLECTIVE_OWNERSHIP: '/collective-ownership',
  TEMPLATE_DOCS: '/template-docs',
  SETTINGS: '/settings',
  WORKFLOWS: '/workflows',
  CONFIGURATIONS: '/configurations',
  EVENTS: '/events'
};

// Main nav subMenuItems
export const subRoutes = {};

// Other routes
export const outOfNavRoutes = {
  LOGIN: '/login',
  CONFIRMATION: '/email-confirmation',
  PROFILE: '/profile',
  SETTINGS: '/settings'
};

// url search parameters
export const pathSearches = {};
