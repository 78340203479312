/**
 * useListContent Hook
 *
 * This hook generates content data for displaying collective ownership details.
 *
 * @param {Object} data - Data containing collective ownership details
 * @param {string} data.name - Name of the collective ownership
 * @param {string} data.federation - Federation of the collective ownership
 * @returns {Object[]} Array of objects with label, span, and content properties
 */

export const useListContent = (data = {}) => {
  const { name, federation } = data;

  return [
    {
      label: 'collective-ownerships.form.name',
      span: 1,
      content: name || '-'
    },
    {
      label: 'collective-ownerships.form.federation',
      span: 2,
      content: federation || '-'
    }
  ];
};
