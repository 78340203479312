import { getChargingGrid } from './getChargingGrid';

/**
 * Deletes a charging grid.
 * @async
 * @function
 * @param {Object} params - Function parameters
 * @param {Object} params.chargingGrid - The charging grid object to be deleted
 * @param {Function} params.dispatchAPI - Function for dispatching API requests
 * @param {Object} params.collectiveOwnership - The collective ownership object associated with the charging grid
 * @param {Function} params.setChargingGrid - Function for setting charging grid data
 * @param {Function} params.setCollectiveOwnership - Function for setting collective ownership data
 * @param {Function} params.setIsVersionButton - Function for setting version button state
 * @throws {Error} Throws an error if the API request fails
 * @returns {Promise<void>} - A promise that resolves after the charging grid is deleted
 */

export const deleteChargingGrid = async ({
  chargingGrid,
  dispatchAPI,
  collectiveOwnership,
  setChargingGrid,
  setCollectiveOwnership,
  setIsVersionButton
}) => {
  const { data } = await dispatchAPI('DELETE', {
    url: `/charging-grids/${chargingGrid._id}?collective_ownership=${collectiveOwnership._id}`
  });

  setCollectiveOwnership(data);
  getChargingGrid({
    collectiveOwnership: data,
    setChargingGrid,
    setIsVersionButton
  });
};
