import React, { useEffect, useState } from 'react';
import { Button, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { TemplateDocsDatatable } from './TemplateDocsDatatable';
import { ContentCustom, PageHeaderCustom } from '../../components';
import { routes } from '../../utils/constants/adminRoutes';

const { TabPane } = Tabs;

const ListTemplateDocs = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [useCases, setUsesCases] = useState([]);
  const navigate = useNavigate();

  const getUseCases = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/template-documents/enums'
      });
      setUsesCases(
        data.configuration.map((config) => ({
          label: config.use_case,
          key: config.use_case,
          children: <TemplateDocsDatatable useCaseParams={config.use_case} />
        }))
      );
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getUseCases();
    })();
  }, []);

  return (
    <>
      <PageHeaderCustom
        title={t('template-documents.title')}
        extra={
          <Button onClick={() => navigate(`${routes.TEMPLATE_DOCS}/create`)}>
            <PlusOutlined />
            {t('buttons.create')}
          </Button>
        }
      />
      <ContentCustom>
        <Tabs tabPosition="left">
          {useCases.map(({ label, key, children }) => (
            <TabPane key={key} tab={t(`template-documents.form.${label}`)}>
              {children}
            </TabPane>
          ))}
        </Tabs>
      </ContentCustom>
    </>
  );
};

export default ListTemplateDocs;
