import { DownloadOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';

/**
 * Custom render function for items in an Ant Design upload list.
 *
 * @function
 * @param {Object} file - The file object of the current file item.
 * @param {React.Node} originNode - The default rendered component of the upload list.
 * @param {Array} fileList - The list of all files.
 * @param {function} downloadFile - A function to handle file download.
 * @returns {React.Node} The custom or default component to render for the current file item.
 */
export const itemRender = (file, originNode, fileList, downloadFile) => {
  const fileName = file?.file?.file?.name || file?.file?.name;
  const fileToRender = fileList.find(
    (fileItem) => fileItem?.rawFile?.metadata.originalName === fileName
  );
  if (fileToRender?.rawFile?._id) {
    return (
      <Row className="custom-fileList-item" style={{ minWidth: 300 }}>
        <Col span={8} style={{ maxHeight: 100, minHeight: 100 }}>
          {originNode}
        </Col>
        <Col span={16}>
          <Row style={{ flexDirection: 'column' }} justify="middle">
            <Col>{fileToRender.rawFile.metadata.originalName}</Col>
            <Col>
              <Button onClick={() => downloadFile(fileToRender.rawFile)}>
                <DownloadOutlined />
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
  return originNode;
};
