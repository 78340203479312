import { Button, Col, Row, Tag } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';
import { routes } from '../../utils/constants/adminRoutes';
import { useState } from 'react';

const Cell = styled.div`
  list-style: none;
  user-select: none;
  width: 100%;
  height: 100%;
`;

export const weekCellRender = (
  eventItem: { id: string; date: Date; startTime?: string; endTime?: string },
  allDay: boolean
) => {
  if (allDay === false) {
    return (
      <Link
        to={{
          pathname: `${routes.EVENTS}/show/${eventItem.id}`
        }}
        className="consultation-event-calendar"
      >
        <Row style={{ justifyContent: 'space-between' }}>
          <Col>{moment(eventItem.date).format('HH:mm')}</Col>
        </Row>
      </Link>
    );
  }
  return (
    <Link
      to={{
        pathname: `${routes.EVENTS}/show/${eventItem.id}`
      }}
      className="consultation-event-calendar"
    >
      <Row style={{ justifyContent: 'space-between' }}>
        <Col>{moment(eventItem.date).format('DD-MM-YYYY')}</Col>
      </Row>
    </Link>
  );
};

export const monthCellRender = (value: moment.Moment, events: Array<any>) => {
  const date = value;

  // Filter should not be changed or just for specific cases
  const eventsToDisplay = (events || []).filter(
    (event) =>
      date.day() !== 0 &&
      date.format('YYYY-MM-DD') >= moment(event.date).format('YYYY-MM-DD') &&
      date.format('YYYY-MM-DD') <= moment(event.date).format('YYYY-MM-DD') &&
      moment(date).isoWeekday() !== 7 &&
      moment(date).isoWeekday() !== 6
  );

  return eventsToDisplay.map((event) => (
    <li key={event._id}>
      <Link
        to={{
          pathname: `${routes.EVENTS}/show/${event._id}`
        }}
      >
        <Tag>
          <Row
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between'
            }}
          >
            <Col>{moment(event?.date).format('LT')}</Col>
          </Row>
        </Tag>
      </Link>
    </li>
  ));
};

export const annualCellRender = (value: moment.Moment, events: Array<any>) => {
  const month = moment(value).month();
  return (
    <Cell>
      {(events || [])
        // Filter and sort should not be changed or just for specific cases
        .filter(
          (event) =>
            month >= moment(event.date).month() &&
            month <= moment(event.date).month()
        )
        .sort((a, b) => moment(b.date).day() - moment(a.date).day())
        // Map is your customizable part
        .map((event) => (
          <li key={event._id}>
            <Link
              to={{
                pathname: `${routes.EVENTS}/show/${event._id}`
              }}
            >
              {moment(event.date).format('DD-MM-YYYY')}
            </Link>
          </li>
        ))}
    </Cell>
  );
};
