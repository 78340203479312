/**
 * useFields Hook
 *
 * This hook generates form fields and their validation rules.
 *
 * @returns {Object} An object containing the form fields
 */

export const useFields = () => {
  const fields = [
    {
      name: ['name'],
      rules: [{ required: true }]
    },
    {
      name: ['address'],
      rules: [{ required: true }]
    },
    {
      name: ['federation'],
      rules: [{ required: true }]
    }
  ];

  return {
    fields
  };
};
