import { useTranslation } from 'react-i18next';
import { PageHeaderCustom } from '../../components';
import { Calendar } from '../../components/Calendar/Calendar';
import {
  annualCellRender,
  monthCellRender,
  weekCellRender
} from '../../components/Calendar/CellRenders.tsx';

export const Home = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageHeaderCustom title={t('home.title')} />
      <Calendar
        modes={['week', 'month', 'year']}
        resourceName="events"
        monthCellRender={monthCellRender}
        weekCellRender={weekCellRender}
        annualCellRender={annualCellRender}
        initialMode="month"
      />
    </>
  );
};
