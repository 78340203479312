/**
 * Opens the label modal if conditions are met.
 * @function
 * @param {Object} params - Function parameters
 * @param {Object} params.collectiveOwnership - The collective ownership data
 * @param {Function} params.setIsLabelModalOpen - Function for setting label modal visibility
 * @returns {boolean} Returns `true` if label modal should be opened, otherwise `false`.
 */

export const openLabelModal = ({ setIsLabelModalOpen, chargingGrid }) => {
  if (!('label' in chargingGrid)) {
    setIsLabelModalOpen(true);
  }
  return false;
};
