import React, { useEffect, useRef } from 'react';
import { add, format } from 'date-fns';
import { Table } from 'antd';

import {
  GenericEvent,
  CalendarBodyProps,
  EventsObject,
  ColumnNode
} from './types.tsx';
import { useTranslation } from 'react-i18next';
import { EventBlock } from './EventBlock.tsx';
import { getDayHoursEvents } from './utils/getDayHoursEvents.tsx';

const ALL_DAY_ROW = 0;

export const Calendar = <T extends GenericEvent>({
  weekDates,
  getDayEvents,
  onEventClick,
  dayRange,
  hourStart,
  hourEnd,
  noAllDayRow
}: CalendarBodyProps<T>) => {
  const { t } = useTranslation();
  const rowRef = useRef<null | HTMLDivElement>(null);
  useEffect(() => {
    if (rowRef.current) {
      rowRef.current?.scrollIntoView();
    }
  }, [rowRef]);

  const dayList = (dayRange: string | undefined) => {
    switch (dayRange) {
      case 'withSaturday':
        return [
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday'
        ];
      case 'weekends':
        return [
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
          'Sunday'
        ];
      default:
        return ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
    }
  };

  const dayColumns = dayList(dayRange).map((day, counter) => {
    const columnDate = add(new Date(weekDates.startDate), {
      days: 1 + counter
    });
    const formattedDayandMonth =
      t(`calendar.days.${format(columnDate, 'iii')}`) +
      ' ' +
      format(columnDate, 'dd');
    return {
      title: formattedDayandMonth,
      dataIndex: day,
      key: day,
      width: 2,
      render: function (
        events: ColumnNode<T>,
        row: EventsObject<T>
      ): React.ReactNode | undefined {
        if (events && events.length > 0 && events instanceof Array) {
          const eventsBlock = events.map(function (
            event,
            index: number
          ): React.ReactNode {
            if (format(event.startTime, 'P') === format(columnDate, 'P')) {
              return (
                <EventBlock
                  key={event.eventId}
                  event={event}
                  index={index}
                  hour={row.hourObject}
                  events={events.length}
                  onEventClick={onEventClick}
                />
              );
            }
          });

          return {
            props: {
              style: { position: 'relative', padding: '0' }
            },
            children: <>{eventsBlock}</>
          };
        }
        return undefined;
      }
    };
  });
  const hourColumn = {
    title: t('calendar.hours.title'),
    dataIndex: 'hour',
    key: 'hour',
    width: 1,
    render: (hour: ColumnNode<T>, {}, id: number) => {
      return {
        props: {
          style: { width: '10%' }
        },
        children: <div>{hour}</div>
      };
    }
  };
  const tableColumns = [hourColumn, ...dayColumns];

  return (
    <div>
      <Table
        rowKey={(record) => record.id}
        dataSource={getDayHoursEvents(
          weekDates,
          getDayEvents,
          hourStart,
          hourEnd,
          noAllDayRow
        )}
        columns={tableColumns}
        pagination={false}
        bordered={true}
        showHeader={true}
        onRow={(_, rowIndex) => {
          if (rowIndex === ALL_DAY_ROW) {
            return {
              style: {
                backgroundColor: 'white',
                position: 'sticky',
                boxShadow: 'rgba(0, 0, 0, 0.05) -1px 4px 4px ',
                zIndex: 1,
                top: 0
              }
            };
          }
          return {};
        }}
        scroll={{
          y: 1000
        }}
      />
    </div>
  );
};

export default Calendar;
