import { Button, Form, Row, Modal, Input, Checkbox } from 'antd';
import PropTypes from 'prop-types';
import { handleAddColumns } from './utils/handleAddColumns';

/**
 * AddColumnModal Component
 *
 * This component displays a modal for adding a new column to the charging grid.
 *
 * @component
 * @param {object} props - The props for the component.
 * @param {boolean} props.isColumnModalOpen - Flag indicating if the modal is open.
 * @param {function} props.setIsColumnModalOpen - Function to set the modal open state.
 * @param {function} props.t - i18n translation function.
 * @param {function} props.dispatchAPI - Function for making API calls.
 * @param {object} props.chargingGrid - The charging grid data.
 * @param {string} props.chargingGrid._id - The ID of the charging grid.
 * @param {function} props.message - Function for displaying messages.
 * @param {function} props.setChargingGrid - Function to set the charging grid data.
 * @param {string} props.id - The ID of the charging grid.
 * @param {function} props.setCollectiveOwnership - Function to set collective ownership data.
 * @param {string} props.duplicateColumn - The name of the duplicated column (if applicable).
 * @param {function} props.setDuplicateColumn - Function to set the duplicated column name.
 * @param {function} props.setIsFinalVersion - Function to set the final version flag.
 * @returns {JSX.Element} React component
 */

export const AddColumnModal = ({
  isColumnModalOpen,
  setIsColumnModalOpen,
  chargingGrid,
  t,
  dispatchAPI,
  message,
  setChargingGrid,
  id,
  setCollectiveOwnership,
  duplicateColumn,
  setDuplicateColumn,
  setIsFinalVersion
}) => (
  <Modal
    title={
      duplicateColumn
        ? t('charging-grids.show.modals.title.duplicate_column', {
            column: duplicateColumn
          })
        : t('charging-grids.show.modals.title.add_column')
    }
    open={isColumnModalOpen}
    onCancel={() => {
      setDuplicateColumn(null);
      setIsColumnModalOpen(false);
    }}
    footer={null}
  >
    <Form
      onFinish={(body) =>
        handleAddColumns({
          body,
          dispatchAPI,
          setIsColumnModalOpen,
          message,
          t,
          chargingGrid,
          setChargingGrid,
          id,
          setCollectiveOwnership,
          duplicateColumn,
          setDuplicateColumn,
          setIsFinalVersion
        })
      }
    >
      <Form.Item
        name={['name']}
        label={t('charging-grids.show.modals.label.column_name')}
      >
        <Input />
      </Form.Item>
      {!duplicateColumn ? (
        <Form.Item
          valuePropName="checked"
          name={['is_text_column']}
          label={t('charging-grids.show.modals.label.column_text')}
        >
          <Checkbox />
        </Form.Item>
      ) : null}
      <Form.Item>
        <Row justify="end" gutter={[6, 6]}>
          <Button
            type="cancel"
            onClick={() => {
              setDuplicateColumn(null);
              setIsColumnModalOpen(false);
            }}
          >
            {`${t('buttons.cancel')} `}
          </Button>
          <Button type="primary" htmlType="submit">
            {`${t('buttons.save')} `}
          </Button>
        </Row>
      </Form.Item>
    </Form>
  </Modal>
);

AddColumnModal.propTypes = {
  isColumnModalOpen: PropTypes.bool.isRequired,
  setIsColumnModalOpen: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  dispatchAPI: PropTypes.func.isRequired,
  chargingGrid: PropTypes.shape({
    _id: PropTypes.string
  }),
  message: PropTypes.func.isRequired,
  setChargingGrid: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  setCollectiveOwnership: PropTypes.func.isRequired,
  duplicateColumn: PropTypes.string,
  setDuplicateColumn: PropTypes.func.isRequired,
  setIsFinalVersion: PropTypes.func.isRequired
};

AddColumnModal.defaultProps = {
  chargingGrid: {},
  duplicateColumn: null
};
