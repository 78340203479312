import { updateChargingGrids } from './updateCollectiveOwnership';

export const handleDivideLot = async ({
  body,
  dataSource,
  antdMessage,
  t,
  setSelectedRowKeys,
  selectedRowKeys,
  dispatchAPI,
  chargingGrid,
  setChargingGrid,
  collectiveOwnership,
  setCollectiveOwnership,
  lotNumber,
  message,
  setIsDivisionLotModalOpen
}) => {
  let hasError = false;

  dataSource.forEach((lot) => {
    if (body.lots.includes(lot.Lot.value) && !lot.delete_row) {
      if (
        body[body.lots.indexOf(lot.Lot.value)] &&
        !body[body.lots.indexOf(lot.Lot.value)].is_duplicate_lot
      ) {
        antdMessage.warning(
          t('charging-grids.show.messages.lot-exists', {
            lot: lot.Lot.value
          }),
          5
        );

        hasError = true;
      }
    }

    if (
      lot.Lot.value !== 'Voulu' &&
      lot.Lot.value !== 'Total' &&
      typeof lot.Lot.value === 'string'
    ) {
      const lotBis = parseInt(lot.Lot.value.replace('bis', '').trim(), 10);

      if (
        body.lots.includes(lotBis) &&
        body[body.lots.indexOf(lotBis)] &&
        body[body.lots.indexOf(lotBis)].is_duplicate_lot
      ) {
        antdMessage.warning(
          t('charging-grids.show.messages.lot-exists', {
            lot: lot.Lot.value
          }),
          5
        );

        hasError = true;
      }
    }
  });

  if (hasError) {
    return;
  }
  try {
    const formData = new FormData();

    const newBody = { ...body };

    body.lots.forEach((lot) => {
      if (body[body.lots.indexOf(lot)].is_duplicate_lot) {
        newBody.lots[body.lots.indexOf(lot)] = `${lot} bis`;
      }
    });

    formData.append('values', JSON.stringify(newBody));
    formData.append('row_keys', JSON.stringify(selectedRowKeys));

    const { data } = await dispatchAPI('PATCH', {
      url: `/charging-grids/${chargingGrid._id}?type=divide_data&lot_number=${lotNumber}`,
      body: formData
    });

    updateChargingGrids({
      collectiveOwnership,
      chargingGrid: data,
      setCollectiveOwnership
    });
    setChargingGrid(data);
    setIsDivisionLotModalOpen(false);
    setSelectedRowKeys([]);
  } catch (error) {
    message(error);
  }
};
