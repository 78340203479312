export const updateChargingGrids = ({
  collectiveOwnership,
  chargingGrid,
  setCollectiveOwnership
}) => {
  const chargingGrids = collectiveOwnership.charging_grid.map((grid) => {
    if (grid.version === chargingGrid.version) {
      return chargingGrid;
    }
    return grid;
  });
  const newCollectiveOwnership = {
    ...collectiveOwnership,
    charging_grid: chargingGrids
  };
  setCollectiveOwnership(newCollectiveOwnership);
};
