export const getNextInputRef = (
  index,
  offset,
  sens,
  inputTableRefs,
  inputRefs,
  isTableRef = false
) => {
  const refs = isTableRef ? inputTableRefs : inputRefs;
  const newIndex = offset;
  const newSubIndex = offset;
  if (sens === 'horizontal') {
    return isTableRef ? refs[newIndex][index].current : refs[newIndex].current;
  }
  if (sens === 'vertical') {
    return refs[index][newSubIndex].current;
  }
  return null;
};

export const findIndex = ({
  startIndex,
  offset,
  data,
  firstKey,
  secondKey,
  thirdKey,
  value,
  type
}) => {
  switch (type) {
    case 'forward': {
      let result = offset;
      for (let i = startIndex; i < data.length; i += 1) {
        if (!data[i][firstKey] && data[i][secondKey][thirdKey] !== value) {
          result = i;
          break;
        }
      }

      if (result === -1) {
        for (let i = 0; i < startIndex; i += 1) {
          if (!data[i][firstKey] && data[i][secondKey][thirdKey] !== value) {
            result = i;
            break;
          }
        }
      }

      return result;
    }
    case 'back': {
      let result = offset;
      for (let i = startIndex; i >= 0; i -= 1) {
        if (!data[i][firstKey] && data[i][secondKey][thirdKey] !== value) {
          result = i;
          break;
        }
      }

      if (result === -1) {
        for (let i = data.length - 1; i > startIndex; i -= 1) {
          if (!data[i][firstKey] && data[i][secondKey][thirdKey] !== value) {
            result = i;
            break;
          }
        }
      }

      return result;
    }

    default:
      break;
  }

  return null;
};

export const editingHandleKeyPress = ({
  e,
  index,
  dataColumns,
  editTable,
  subindex,
  dataSource,
  handleSaveAllTableButtonRef,
  setEditTable,
  inputTableRefs,
  inputRefs
}) => {
  let nextInput;

  switch (e.key) {
    case 'Alt':
      {
        e.preventDefault();

        const offset = findIndex({
          startIndex: index + 1,
          offset: -1,
          data: dataColumns,
          firstKey: 'delete_column',
          secondKey: 'column',
          thirdKey: 'name',
          value: 'Lot',
          type: 'forward'
        });

        nextInput = getNextInputRef(
          subindex,
          offset,
          'horizontal',
          inputTableRefs,
          inputRefs,
          editTable
        );
      }
      break;
    case 'Tab':
      {
        e.preventDefault();

        const offset = findIndex({
          startIndex: index - 1,
          offset: -1,
          data: dataColumns,
          firstKey: 'delete_column',
          secondKey: 'column',
          thirdKey: 'name',
          value: 'Lot',
          type: 'back'
        });

        nextInput = getNextInputRef(
          subindex,
          offset,
          'horizontal',
          inputTableRefs,
          inputRefs,
          editTable
        );
      }
      break;
    case 'ArrowUp':
      {
        e.preventDefault();

        const offset = findIndex({
          startIndex: subindex - 1,
          offset: -1,
          data: dataSource,
          firstKey: 'delete_row',
          secondKey: 'Lot',
          thirdKey: 'value',
          value: 'Total',
          type: 'back'
        });

        nextInput = getNextInputRef(
          index,
          offset,
          'vertical',
          inputTableRefs,
          inputRefs,
          true
        );
      }

      break;
    case 'ArrowDown':
      {
        e.preventDefault();

        const offset = findIndex({
          startIndex: subindex + 1,
          offset: -1,
          data: dataSource,
          firstKey: 'delete_row',
          secondKey: 'Lot',
          thirdKey: 'value',
          value: 'Total',
          type: 'forward'
        });

        nextInput = getNextInputRef(
          index,
          offset,
          'vertical',
          inputTableRefs,
          inputRefs,
          true
        );
      }

      break;
    case 'Enter':
      e.preventDefault();
      handleSaveAllTableButtonRef.current?.click();
      return;
    case 'Escape':
      e.preventDefault();
      setEditTable(false);
      return;
    default:
      break;
  }

  if (nextInput) nextInput.focus();
};
