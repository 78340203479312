import { useState } from 'react';
import { Input, Upload, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { UploadOutlined } from '@ant-design/icons/lib';

const { Dragger } = Upload;
const { Option } = Select;

const useFields = () => {
  const { t } = useTranslation();
  const [file, setFile] = useState([]);

  const draggerProps = {
    multiple: false,
    fileList: file,
    beforeUpload: (newFile) => {
      setFile([newFile]);
      return false;
    },
    onRemove: (fileObject) =>
      setFile(file.filter((f) => f.uid !== fileObject.uid))
  };

  const templatesType = [
    { label: 'Grille de charges', value: 'charging_grid' }
  ];

  const fields = [
    {
      name: ['label'],
      input: <Input style={{ width: '100%' }} />
    },
    {
      name: ['type'],
      input: (
        <Select style={{ width: '100%' }}>
          {templatesType.map((item) => (
            <Option key={item.value} value={item.value}>
              {item.label}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['file'],
      input: (
        <Dragger {...draggerProps}>
          <p className="ant-upload-drag-icon">
            <UploadOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    }
  ];

  return {
    fields,
    file
  };
};

export default useFields;
