import { Modal } from 'antd';
import { beforeUpload } from './beforeUpload';
import { itemRender } from './itemRender';
import { onPreview } from './onPreview';
import { onRemove } from './onRemove';

/**
 * Generates the properties for a file uploader component.
 *
 * @function
 * @param {Array} fileList - The list of files currently uploaded.
 * @param {function} setFileList - A setter function for the file list.
 * @param {string} purpose - The purpose of the createUpdateContainer.
 * @param {Array} filesConfiguration - The configuration of the files.
 * @param {function} deleteFile - A function for deleting a file.
 * @param {function} downloadFile - A function for downloading a file.
 * @param {function} showFile - A function for displaying a file.
 * @param {Array} name - An array representing the name of the field associated with the file.
 * @param {function} setFilesConfiguration - A setter function for the files configuration.
 * @param {function} message - A function for displaying messages.
 * @param {boolean} multipleFiles - A flag indicating whether multiple files can be uploaded.
 * @param {number} maxFilesCount - The maximum count of files that can be uploaded.
 * @param {function} t - A function for translating message strings.
 * @param {function} setFilesToUpload - A function to set filesToUpload.
 * @returns {Object} An object containing the properties for a file uploader component.
 */
export const useDraggerProps = (
  fileList,
  setFileList,
  purpose,
  filesConfiguration,
  deleteFile,
  downloadFile,
  showFile,
  name,
  setFilesConfiguration,
  message,
  multipleFiles,
  maxFilesCount,
  t,
  setFilesToUpload
) => ({
  multiple: multipleFiles,
  maxCount: maxFilesCount,
  listType: 'picture-card',
  onRemove: (file) => {
    Modal.confirm({
      title: t('files.modal.delete_title'),
      content: t('files.modal.delete_content'),
      okText: t('files.modal.yes'),
      okType: 'danger',
      cancelText: t('files.modal.no'),
      onOk: async () => {
        await onRemove(
          name,
          fileList,
          file,
          filesConfiguration,
          setFilesConfiguration,
          setFileList,
          purpose,
          deleteFile,
          message,
          setFilesToUpload
        );
      }
    });
  },
  onPreview: (file) => onPreview(file, showFile),
  showUploadList: true,
  beforeUpload: async (file) => {
    await beforeUpload(
      file,
      fileList,
      filesConfiguration,
      setFileList,
      setFilesConfiguration,
      maxFilesCount,
      deleteFile,
      message,
      t,
      name,
      setFilesToUpload
    );
  },
  itemRender: (originNode, file) =>
    itemRender(file, originNode, fileList, downloadFile),
  fileList
});
