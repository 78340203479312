import { InboxOutlined } from '@ant-design/icons';
import { Input, Upload } from 'antd';

const { Dragger } = Upload;

/**
 * Decides the display input type based on the provided parameters.
 *
 * @function
 * @param {boolean} isFileInput - Flag to check if the input is of type file.
 * @param {ReactElement} input - The Input component to be displayed.
 * @param {Object} draggerProps - Properties to pass to the Dragger component.
 * @param {boolean} disabled - Flag to check if the input is disabled.
 * @param {function} t - Translation function for internationalization support.
 * @returns {ReactElement} - Returns a Dragger component for file input, or the provided input element, or a disabled Input element based on the conditions.
 */
export const inputDisplay = (isFileInput, input, draggerProps, disabled, t) => {
  if (isFileInput) {
    return (
      <Dragger {...draggerProps}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">{t('files.create.action')}</p>
      </Dragger>
    );
  }
  if (input) {
    return input;
  }
  return <Input disabled={disabled} />;
};
