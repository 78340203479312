export const getTemplate = async ({ dispatchAPI, setTemplate, message }) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: `/template?type=charging_grid`
    });
    setTemplate(data);
  } catch (e) {
    if (e.response) message(e.response);
  }
};
