import { useState, useEffect } from 'react';
import {
  Button,
  Form,
  Row,
  Select,
  InputNumber,
  Modal,
  Typography,
  Input,
  Col
} from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { editMultipleRows } from './utils/editMultipleRows';

const { Option } = Select;
const { Text } = Typography;

/**
 * EditLotModal Component
 *
 * This component displays a modal for editing lots in the charging grid.
 *
 * @component
 * @param {object} props - The props for the component.
 * @param {boolean} props.isEditLotModalOpen - Flag indicating if the modal is open.
 * @param {function} props.setIsEditLotModalOpen - Function to set the modal open state.
 * @param {boolean} props.isLoading - Flag indicating if data is loading.
 * @param {object} props.chargingGrid - The charging grid data.
 * @param {string} props.chargingGrid._id - The ID of the charging grid.
 * @param {array} props.dataSource - The data source for the table.
 * @param {function} props.dispatchAPI - Function for making API calls.
 * @param {function} props.message - Function for displaying messages.
 * @param {function} props.t - i18n translation function.
 * @param {array} props.selectedRowKeys - The selected row keys in the table.
 * @param {function} props.setIsFinalVersion - Function to set the final version state.
 * @returns {JSX.Element} React component
 */

export const EditLotModal = ({
  isEditLotModalOpen,
  setIsEditLotModalOpen,
  isLoading,
  chargingGrid,
  dataSource,
  setChargingGrid,
  dispatchAPI,
  message,
  t,
  selectedRowKeys,
  setIsFinalVersion,
  setSelectedRowKeys,
  collectiveOwnership,
  setCollectiveOwnership
}) => {
  const [form] = Form.useForm();
  const [inputText, setInputText] = useState(false);
  const [rangeMode, setRangeMode] = useState(false);
  const [columnMode, setColumnMode] = useState(false);
  const [columnsToCopy, setColumnToCopy] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState(null);

  useEffect(() => {
    form.resetFields();
    setRangeMode(false);
    setColumnMode(false);
  }, [isEditLotModalOpen, form]);

  useEffect(() => {
    if (selectedColumn) {
      const columns = chargingGrid.columns
        .filter((column) => column.column.name !== selectedColumn.column.name)
        .filter(
          (column) => column.is_text_column === selectedColumn.is_text_column
        );
      setColumnToCopy(columns);
    }
  }, [selectedColumn]);

  const handleSubmit = async () => {
    const body = await form.validateFields();

    editMultipleRows({
      body,
      dispatchAPI,
      chargingGrid,
      setChargingGrid,
      setIsEditLotModalOpen,
      message,
      selectedRowKeys,
      setIsFinalVersion,
      setSelectedRowKeys,
      collectiveOwnership,
      setCollectiveOwnership,
      dataSource
    });

    setIsEditLotModalOpen(false);
  };

  return (
    <Modal
      title={t('charging-grids.show.modals.title.edit')}
      open={isEditLotModalOpen}
      onCancel={() => setIsEditLotModalOpen(false)}
      footer={null}
    >
      <Form form={form}>
        <Form.Item
          required
          name={['column']}
          label={t('charging-grids.show.modals.label.column')}
        >
          <Select
            loading={isLoading}
            showSearch
            allowClear
            onChange={(e, option) => {
              setInputText(option.isTextColumn);
              setSelectedColumn(option.columnObject);
            }}
            style={{ minWidth: 150 }}
            filterOption={(input, option) =>
              (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
            }
          >
            {(chargingGrid.columns || [])
              .filter(
                (ColumnObject) =>
                  ColumnObject.column.name !== 'Lot' &&
                  !ColumnObject.delete_column
              )
              .map((columnObject) => (
                <Option
                  key={columnObject.column.name}
                  value={columnObject.column.name}
                  isTextColumn={columnObject.is_text_column}
                  columnObject={columnObject}
                >
                  {columnObject.column.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
        {selectedRowKeys.length === 0 ? (
          <Row>
            <Col span={12}>
              <Form.Item
                required={!rangeMode}
                name={['lots']}
                label={t('charging-grids.show.modals.label.lots')}
              >
                <Select
                  disabled={rangeMode}
                  loading={isLoading}
                  showSearch
                  allowClear
                  mode="multiple"
                  style={{ minWidth: 150 }}
                  filterOption={(input, option) =>
                    (option?.children ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {(dataSource || [])
                    .filter(
                      (data) =>
                        data.Lot.value !== 'Voulu' &&
                        data.Lot.value !== 'Total' &&
                        !data.delete_row
                    )
                    .map((data) => (
                      <Option key={data.key} value={data.key}>
                        {`Lot ${data.Lot.value}`}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col
              span={12}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              {!rangeMode ? (
                <Button onClick={() => setRangeMode(true)}>
                  {t('charging-grids.show.modals.buttons.select_by_range')}
                </Button>
              ) : (
                <Button onClick={() => setRangeMode(false)}>
                  <CloseCircleOutlined />
                </Button>
              )}
            </Col>

            {rangeMode && (
              <Form.Item
                label={t('charging-grids.show.modals.label.range')}
                name={['range']}
                rules={[
                  {
                    required: rangeMode,
                    message: t(
                      'charging-grids.show.modals.messages.range_required'
                    )
                  }
                ]}
              >
                <Input.Group compact>
                  <Form.Item name={['range', 'range_start']} noStyle>
                    <Select
                      placeholder={t(
                        'charging-grids.show.modals.place_holder.start'
                      )}
                      loading={isLoading}
                      showSearch
                      allowClear
                      style={{ minWidth: 150 }}
                      filterOption={(input, option) =>
                        (option?.children ?? '')
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {(dataSource || [])
                        .filter(
                          (data) =>
                            data.Lot.value !== 'Voulu' &&
                            data.Lot.value !== 'Total' &&
                            !data.delete_row
                        )
                        .map((data) => (
                          <Option key={data.key} value={data.key}>
                            {`Lot ${data.Lot.value}`}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <Form.Item name={['range', 'range_end']} noStyle>
                    <Select
                      placeholder={t(
                        'charging-grids.show.modals.place_holder.end'
                      )}
                      loading={isLoading}
                      showSearch
                      allowClear
                      style={{ minWidth: 150 }}
                      filterOption={(input, option) =>
                        (option?.children ?? '')
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {(dataSource || [])
                        .filter(
                          (data) =>
                            data.Lot.value !== 'Voulu' &&
                            data.Lot.value !== 'Total' &&
                            !data.delete_row
                        )
                        .map((data) => (
                          <Option key={data.key} value={data.key}>
                            {`Lot ${data.Lot.value}`}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            )}
          </Row>
        ) : (
          dataSource
            .filter((el) => selectedRowKeys.includes(el.key))
            .map((el) => <Text>{`Lot ${el.Lot.value}`}</Text>)
        )}
        <Row>
          <Col span={12}>
            <Form.Item
              required={!columnMode}
              name={['value']}
              label={t('charging-grids.show.modals.label.value')}
            >
              {inputText ? (
                <Input disabled={columnMode} />
              ) : (
                <InputNumber disabled={columnMode} />
              )}
            </Form.Item>
          </Col>
          <Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
            {!columnMode ? (
              <Button onClick={() => setColumnMode(true)}>
                {t('charging-grids.show.modals.buttons.select_by_column')}
              </Button>
            ) : (
              <Button onClick={() => setColumnMode(false)}>
                <CloseCircleOutlined />
              </Button>
            )}
          </Col>
        </Row>

        {columnMode && (
          <Form.Item
            required={columnMode}
            name={['column_to_copy']}
            label={t('charging-grids.show.modals.label.column_to_copy')}
          >
            <Select
              loading={isLoading}
              showSearch
              allowClear
              style={{ minWidth: 150 }}
              filterOption={(input, option) =>
                (option?.value ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {(columnsToCopy || [])
                .filter(
                  (ColumnObject) =>
                    ColumnObject.column.name !== 'Lot' &&
                    !ColumnObject.delete_column
                )
                .map((columnObject) => (
                  <Option
                    key={columnObject.column.name}
                    value={columnObject.column.name}
                  >
                    {columnObject.column.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item>
          <Row justify="end" gutter={[6, 6]}>
            <Button type="cancel" onClick={() => setIsEditLotModalOpen(false)}>
              {`${t('buttons.cancel')} `}
            </Button>
            <Button type="primary" onClick={() => handleSubmit()}>
              {`${t('buttons.save')} `}
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

EditLotModal.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  t: PropTypes.func.isRequired,
  dispatchAPI: PropTypes.func.isRequired,
  chargingGrid: PropTypes.shape({
    _id: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  message: PropTypes.func.isRequired,
  setChargingGrid: PropTypes.func.isRequired,
  isEditLotModalOpen: PropTypes.bool.isRequired,
  setIsEditLotModalOpen: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  selectedRowKeys: PropTypes.arrayOf(PropTypes.string),
  setIsFinalVersion: PropTypes.func.isRequired,
  setSelectedRowKeys: PropTypes.func.isRequired,
  collectiveOwnership: PropTypes.bool.isRequired,
  setCollectiveOwnership: PropTypes.func.isRequired
};

EditLotModal.defaultProps = {
  chargingGrid: {},
  selectedRowKeys: []
};
