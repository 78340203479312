import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Datatable } from '../../components';
import { useColumns } from './columns';

export const TemplateDocsDatatable = ({ useCaseParams }) => {
  const [forceRefresh, setForceRefresh] = useState(false);
  const columns = useColumns(forceRefresh, setForceRefresh);

  return (
    <Datatable
      columns={columns}
      resourceName="template-documents"
      forceRefresh={forceRefresh}
      extraQuery={`configuration.use_case=${useCaseParams}&populate=author`}
    />
  );
};

TemplateDocsDatatable.propTypes = {
  useCaseParams: PropTypes.string.isRequired
};
