import { Route, Routes } from 'react-router-dom';
import { ListCollectiveOwnerships } from './ListCollectiveOwnerships';
import { CreateUpdateCollectiveOwnership } from './CreateUpdateCollectiveOwnership';
import { ShowCollectiveOwnership } from './ShowCollectiveOwnership';
import { Exception } from '../../components';

export const CollectiveOwnershipRouter = () => (
  <Routes>
    <Route
      path="/create"
      element={<CreateUpdateCollectiveOwnership purpose="create" />}
    />
    <Route
      path="/edit/:id"
      element={<CreateUpdateCollectiveOwnership purpose="edit" />}
    />
    <Route path="/show/:id" element={<ShowCollectiveOwnership />} />
    <Route index element={<ListCollectiveOwnerships />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
