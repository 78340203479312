import { message as antdMessage } from 'antd';

export const handleDoubleClick = ({
  rowEditing,
  dataSource,
  selectedRowKey,
  t,
  setEditKey,
  setRowEditing,
  form,
  record
}) => {
  if (!rowEditing) {
    if (dataSource[selectedRowKey].Lot.value === 'Total') {
      return antdMessage.warning(
        t('charging-grids.show.messages.edit_total_not_allowed')
      );
    }

    setEditKey(record.key);

    const transformedRecord = {};

    Object.keys(record).forEach((k) => {
      const value = record[k].value;

      if (typeof record[k] === 'object' && !value) {
        transformedRecord[k] = null;
      } else {
        transformedRecord[k] = record[k].value;
      }
    });

    setRowEditing(!rowEditing);
    form.setFieldsValue({ ...transformedRecord });
  }

  return null;
};
