import { message as antdMessage } from 'antd';

/**
 * Handles the addition of columns to the charging grid.
 * @function
 * @param {Object} params - Function parameters
 * @param {Object} params.body - The data body containing column details
 * @param {Function} params.dispatchAPI - Function for dispatching API requests
 * @param {Function} params.setIsColumnModalOpen - Function for setting column modal state
 * @param {Function} params.message - Function for displaying messages
 * @param {Function} params.t - Function for translating strings
 * @param {Object} params.chargingGrid - The charging grid object
 * @param {Function} params.setChargingGrid - Function for setting charging grid data
 * @param {string} params.id - The ID of the collective ownership
 * @param {Function} params.setCollectiveOwnership - Function for setting collective ownership data
 * @param {string|null} params.duplicateColumn - The name of the column to duplicate (if any)
 * @param {Function} params.setDuplicateColumn - Function for setting the duplicated column
 * @param {Function} params.setIsFinalVersion - Function for setting final version state
 * @returns {null}
 */

export const handleAddColumns = async ({
  body,
  dispatchAPI,
  setIsColumnModalOpen,
  message,
  t,
  chargingGrid,
  setChargingGrid,
  id,
  setCollectiveOwnership,
  duplicateColumn,
  setDuplicateColumn,
  setIsFinalVersion
}) => {
  try {
    const newBody = { ...body };

    if (duplicateColumn) {
      const column = chargingGrid.columns.find(
        (columnObject) => columnObject.column.name === duplicateColumn
      );
      const isTextColumn = column.is_text_column;
      newBody.is_text_column = isTextColumn;
    }

    const isDuplicateName = chargingGrid.columns.some(
      (item) => item.column.name === newBody.name && !item.delete_column
    );

    if (isDuplicateName && !isDuplicateName.delete_column) {
      return antdMessage.warning(
        t('charging-grids.show.messages.column_exist', {
          column: newBody.name
        }),
        5
      );
    }

    const formData = new FormData();

    formData.append(
      'values',
      JSON.stringify({
        ...newBody
      })
    );

    const { data } = await dispatchAPI('POST', {
      url: `/charging-grid-columns?charging_grid=${chargingGrid._id}&duplicate_column=${duplicateColumn}`,
      body: formData
    });
    setIsColumnModalOpen(false);

    try {
      const collectiveOwnershipData = await dispatchAPI('GET', {
        url: `/collective-ownership/${id}?populate=charging_grid,charging_grid.columns.column`
      });
      setCollectiveOwnership(collectiveOwnershipData.data);
      setChargingGrid(data);
      setDuplicateColumn(null);
    } catch (e) {
      message(e);
    }
  } catch (e) {
    message(e);
  }
  setIsFinalVersion(false);
  return null;
};
