/**
 * Handles the cancellation of editing.
 * @function
 * @param {Object} params - Function parameters
 * @param {Function} params.setChargingGrid - Function for setting charging grid data
 * @param {Function} params.dispatchAPI - Function for dispatching API requests
 * @param {Function} params.message - Function for displaying messages
 * @param {Object} params.chargingGrid - The charging grid object
 * @param {Function} params.setEditKey - Function for setting the edit key
 * @returns {null}
 */

export const handleCancel = async ({
  setChargingGrid,
  dispatchAPI,
  message,
  chargingGrid,
  setEditKey
}) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: `/charging-grids/${chargingGrid._id}`
    });

    setEditKey(undefined);
    setChargingGrid(data);
  } catch (e) {
    message(e);
  }
};
