import { useTranslation } from 'react-i18next';
import { Layout, Collapse } from 'antd';
import { PageHeaderCustom } from '../../components';
import ListTemplate from './templates/ListTemplates';

const { Content } = Layout;
const { Panel } = Collapse;

export const Settings = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageHeaderCustom title={t('settings.title')} />

      <Content
        className="site-layout-background"
        style={{
          marginTop: 2,
          padding: 24,
          backgroundColor: '#fff'
        }}
      >
        <Collapse>
          <Panel header={t('settings.template.title')} key="1">
            <ListTemplate />
          </Panel>
        </Collapse>
      </Content>
    </>
  );
};
