import { updateChargingGrids } from './updateCollectiveOwnership';

/**
 * Updates the column name in the charging grid.
 *
 * @function
 * @async
 * @param {Object} options - The options object.
 * @param {string} options.columnId - The ID of the column to update.
 * @param {Object} options.charging_grid - The charging grid object.
 * @param {Function} options.dispatchAPI - The dispatchAPI function.
 * @param {Function} options.message - The message function.
 * @param {Function} options.setChargingGrid - The setChargingGrid function.
 * @param {Function} options.setIsFinalVersion - The setIsFinalVersion function.
 * @param {Object} options.collectiveOwnership - The collectiveOwnership object.
 * @param {Function} options.setCollectiveOwnership - The setCollectiveOwnership function.
 * @param {string} options.value - The new value for the column name.
 * @param {Function} options.setIsEditingColumnName - The setIsEditingColumnName function.
 * @returns {Promise<void>} - A promise that resolves when the column name is updated.
 */
export const handleUpdateColumnName = async ({
  columnId,
  charging_grid,
  dispatchAPI,
  message,
  setChargingGrid,
  setIsFinalVersion,
  collectiveOwnership,
  setCollectiveOwnership,
  value,
  setIsEditingColumnName
}) => {
  try {
    const formData = new FormData();
    formData.append('values', JSON.stringify({ name: value }));

    const { data } = await dispatchAPI('PATCH', {
      url: `/charging-grids/${charging_grid._id}?type=update_column&column_id=${columnId}`,
      body: formData
    });

    setIsEditingColumnName(null);
    updateChargingGrids({
      collectiveOwnership,
      chargingGrid: data,
      setCollectiveOwnership
    });
    setChargingGrid(data);
  } catch (e) {
    message(e);
  }
  setIsFinalVersion(false);
};
