export const handleEditAllTable = ({
  setEditTable,
  editTable,
  setSelectedRowKey,
  dataSource,
  form
}) => {
  setEditTable(!editTable);
  setSelectedRowKey(null);
  const transformedRecord = {};

  dataSource.forEach((element) => {
    const key = `key${element.key}`;

    const newRecord = {};

    Object.keys(element).forEach((k) => {
      const value = element[k].value;

      if (typeof element[k] === 'object' && !value) {
        newRecord[k] = null;
      } else {
        newRecord[k] = element[k].value;
      }
    });

    transformedRecord[key] = newRecord;
  });

  form.setFieldsValue(transformedRecord);
};
