/**
 * Fetches a file by its id from a server, and creates a new File object with the returned blob data.
 * It also provides a url property that contains the data URL of the blob data.
 *
 * @function
 * @async
 * @param {Object} document - Document object that has a file property which contains metadata about the file.
 * @param {function} dispatchAPI - A function to dispatch API calls.
 * @param {function} message - A function to handle error messages.
 * @returns {Promise<Object>} Returns a Promise that resolves with an object containing the original document
 * data and the new file, or throws an error message.
 */
export const createFileFromJSON = async (document, dispatchAPI, message) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: `/files/${document.file._id}`,
      responseType: 'blob'
    });

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result;
        const file = {
          ...document,
          url: base64data,
          file: new File([data], document.file.metadata.originalName, {
            type: data.type
          })
        };
        resolve(file);
      };
      reader.onerror = reject;
      reader.readAsDataURL(data);
    });
  } catch (e) {
    return message(e);
  }
};
