import { useEffect } from 'react';
import {
  Button,
  Form,
  Row,
  InputNumber,
  Modal,
  Checkbox,
  Col,
  Input,
  message as antdMessage
} from 'antd';
import PropTypes from 'prop-types';
import { handleMergeLots } from './utils/handleMergeLots';

/**
 * MergeLotModal Component
 *
 * This component displays a modal for merging lots in the charging grid.
 *
 * @component
 * @param {object} props - The props for the component.
 * @param {boolean} props.isMergeLotModalOpen - Flag indicating if the modal is open.
 * @param {function} props.setIsMergeLotModalOpen - Function to set the modal open state.
 * @param {array} props.selectedRowKeys - The selected row keys in the table.
 * @param {function} props.setSelectedRowKeys - Function to set the selected row keys.
 * @param {array} props.dataSource - The data source for the table.
 * @param {function} props.t - i18n translation function.
 * @param {function} props.dispatchAPI - Function for making API calls.
 * @param {object} props.chargingGrid - The charging grid data.
 * @param {string} props.chargingGrid._id - The ID of the charging grid.
 * @param {function} props.message - Function for displaying messages.
 * @param {function} props.setChargingGrid - Function to set the charging grid data.
 * @returns {JSX.Element} React component
 */

const { TextArea } = Input;

export const MergeLotModal = ({
  isMergeLotModalOpen,
  setIsMergeLotModalOpen,
  selectedRowKeys,
  setSelectedRowKeys,
  dataSource,
  t,
  dispatchAPI,
  chargingGrid,
  message,
  setChargingGrid,
  collectiveOwnership,
  setCollectiveOwnership
}) => {
  const [form] = Form.useForm();

  const getLatestLotNumber = () => {
    if (dataSource.length) {
      const lotNumbers = dataSource
        .filter(
          (obj) =>
            obj.Lot.value !== 'Voulu' &&
            obj.Lot.value !== 'Total' &&
            typeof obj.Lot.value !== 'string'
        )
        .map((objet) => objet.Lot.value);

      form.setFieldsValue({
        lot: Math.max(...lotNumbers) + 1
      });
    }
  };

  useEffect(() => {
    getLatestLotNumber();
  }, [dataSource]);

  return (
    <Modal
      forceRender
      title={t('charging-grids.show.modals.title.merge')}
      open={isMergeLotModalOpen}
      onCancel={() => {
        setIsMergeLotModalOpen(false);
        setSelectedRowKeys([]);
      }}
      footer={null}
    >
      <Form
        form={form}
        onFinish={(body) =>
          handleMergeLots({
            body,
            dataSource,
            antdMessage,
            t,
            selectedRowKeys,
            dispatchAPI,
            chargingGrid,
            setChargingGrid,
            collectiveOwnership,
            setCollectiveOwnership,
            setIsMergeLotModalOpen,
            message,
            setSelectedRowKeys
          })
        }
      >
        <Row justify="space-between">
          <Col>
            <Form.Item
              name={['lot']}
              label={t('charging-grids.show.modals.label.lot_number')}
            >
              <InputNumber />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name={['is_duplicate_lot']} valuePropName="checked">
              <Checkbox>
                {t('charging-grids.show.modals.label.is_duplicate_lot')}
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Item
              name={['comment']}
              label={t('charging-grids.show.modals.label.comment')}
            >
              <TextArea />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Row justify="end">
            <Button
              type="cancel"
              onClick={() => {
                setIsMergeLotModalOpen(false);
                setTimeout(() => {
                  setSelectedRowKeys([]);
                }, 1000);
              }}
            >
              {`${t('buttons.cancel')} `}
            </Button>
            <Button type="primary" htmlType="submit">
              {`${t('buttons.save')} `}
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

MergeLotModal.propTypes = {
  isMergeLotModalOpen: PropTypes.bool.isRequired,
  setIsMergeLotModalOpen: PropTypes.func.isRequired,
  selectedRowKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedRowKeys: PropTypes.func.isRequired,
  dataSource: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  t: PropTypes.func.isRequired,
  dispatchAPI: PropTypes.func.isRequired,
  chargingGrid: PropTypes.shape({
    _id: PropTypes.string
  }),
  message: PropTypes.func.isRequired,
  setChargingGrid: PropTypes.func.isRequired,
  collectiveOwnership: PropTypes.shape({}),
  setCollectiveOwnership: PropTypes.func.isRequired
};

MergeLotModal.defaultProps = {
  chargingGrid: {},
  collectiveOwnership: {}
};
