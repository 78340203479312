import { createGlobalStyle } from 'styled-components';
import '../SCSS/user/index.scss';

export const UserTheme = createGlobalStyle`
  body {
    --clientColor: #fff;
    --primaryColor: #222222;
    --addColor: #368540;
    --itemHoverColor: var(--primaryColor);
    --componentBackground: #F4F4F4;
    --secondaryHover: var(--componentBackground);
    --subMenuBackground: var(--componentBackground);
    --menuDropdownBackground: var(--componentBackground);
    --pickerDropdown: var(--componentBackground);
    --primaryHover:  var(--secondaryColor);
    --secondaryColor: #FFD53F;
    --disabledColor: #bfbfbf;
    --bodyBackground: var(--componentBackground);
    --textColor: var(--primaryColor);
    --textColorSecondary: var(--bodyBackground);
    --itemActiveBackground: #F5F0DE;
    --itemHoverBackground: #F5F0DE;
    --borderColor: #d9d9d9;
    --contrastBackground: var(--clientColor);
    --errorColor: #F33C52;
    --errorColorLight: #FCE4E7;
    --addColorLight: #E3FFE6;
    --gray800: #343A40;
    --opacitySVG: 1;
    --primaryColorA04
    --primaryColorA07
    --primaryContrast
    --tabsGray
    --textColorInvert
    --textColorHover
    --textColorA015
    --textColorA075
    --borderColorA06
    --disabledColorA04
    --disabledColorA06
    --itemActiveColor
    --logo
    --logoSmall
    --error100
    --errorA08
  }
`;
