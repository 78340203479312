export const setRowsColors = ({ record, dataSource, selectedRowKey }) => {
  if (record.new_row) {
    if (record.key === dataSource[selectedRowKey]?.key) {
      return 'row-color-added-selected';
    }
    return 'row-color-added';
  }
  if (record.delete_row) {
    if (record.key === dataSource[selectedRowKey]?.key) {
      return 'row-color-deleted-selected';
    }
    return 'row-color-deleted';
  }

  if (record.key === dataSource[selectedRowKey]?.key) {
    return 'row-border-selected';
  }
  return null;
};
