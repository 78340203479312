import { useEffect } from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useLocation
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { outOfNavRoutes, routes } from '../utils/constants/adminRoutes';
import { Login } from '../routes/login';
import { Home } from '../routes/home/Home';
import { Exception } from '../components';
import { useAuthContext } from '../contexts/AuthContext';
import { AdminLayout } from '../components/layouts/AdminLayout';
import { ProfileRouter } from '../routes/profile/ProfileRouter';
import { Confirmation } from '../routes/emailConfirmation/Confirmation';
import { UserRouter } from '../routes/users/UserRouter';
import { CollectiveOwnershipRouter } from '../routes/collective-ownership/CollectiveOwnershipRouter';
import { AdminTheme } from '../assets/styles/Theme/AdminTheme';
import ConfigurationRouter from './ConfigurationRouter';

const RequireAuth = ({ isValid }) => {
  const location = useLocation();

  if (!isValid) {
    return Navigate({
      to: {
        pathname: outOfNavRoutes.LOGIN
      },
      state: { from: location }
    });
  }

  return <AdminLayout />;
};

RequireAuth.propTypes = {
  isValid: PropTypes.bool.isRequired
};

export const AdminRouter = () => {
  const { isValid } = useAuthContext();

  useEffect(() => {
    const body = document.body;
    if (body) {
      body.className = 'admin';
    }
  });

  return (
    <BrowserRouter>
      <AdminTheme />
      <Routes>
        <Route path={`${outOfNavRoutes.LOGIN}/:token`} element={<Login />} />
        <Route path={outOfNavRoutes.LOGIN} element={<Login />} />
        <Route
          path={`${outOfNavRoutes.CONFIRMATION}/:token`}
          element={<Confirmation />}
        />
        <Route element={<RequireAuth isValid={isValid} />}>
          <Route
            path={`${outOfNavRoutes.PROFILE}/*`}
            element={<ProfileRouter />}
          />
          <Route path={routes.HOME} element={<Home />} />
          <Route path={`${routes.USERS}/*`} element={<UserRouter />} />
          <Route
            path={`${routes.COLLECTIVE_OWNERSHIP}/*`}
            element={<CollectiveOwnershipRouter />}
          />

          <Route
            path={routes.CONFIGURATIONS}
            element={<ConfigurationRouter />}
          />
          <Route path="*" element={<Exception />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
