import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
  Button,
  Form,
  Input,
  Row,
  Modal,
  message as antdMessage,
  DatePicker
} from 'antd';
import PropTypes from 'prop-types';
import { handleAddLabelAndDate } from './utils/handlAddLabelAndDate';

/**
 * AddLabelAndDateModal Component
 *
 * This component displays a modal for adding a label and date to the charging grid.
 *
 * @component
 * @param {object} props - The props for the component.
 * @param {boolean} props.isLabelModalOpen - Flag indicating if the modal is open.
 * @param {function} props.dispatchAPI - Function for making API calls.
 * @param {object} props.chargingGrid - The charging grid data.
 * @param {string} props.chargingGrid._id - The ID of the charging grid.
 * @param {function} props.setChargingGrid - Function to set the charging grid data.
 * @param {function} props.setIsLabelModalOpen - Function to set the modal open state.
 * @param {function} props.message - Function for displaying messages.
 * @param {function} props.t - i18n translation function.
 * @returns {JSX.Element} React component
 */

export const AddLabelAndDateModal = ({
  isLabelModalOpen,
  dispatchAPI,
  chargingGrid,
  setChargingGrid,
  setIsLabelModalOpen,
  message,
  t,
  collectiveOwnership,
  setCollectiveOwnership,
  isEditLabel
}) => {
  const [form] = Form.useForm();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  useEffect(() => {
    if (isLabelModalOpen) {
      setIsCalendarOpen(true);
    }
  }, [isLabelModalOpen]);

  useEffect(() => {
    if (isEditLabel) {
      form.setFieldsValue({
        label: chargingGrid.label,
        document_date: dayjs(chargingGrid.document_date)
      });
    }
  }, [isEditLabel]);

  return (
    <Modal
      title={t('charging-grids.show.modals.title.label_and_date')}
      open={isLabelModalOpen}
      onCancel={() =>
        isEditLabel
          ? (setIsCalendarOpen(false),
            setTimeout(() => {
              setIsLabelModalOpen(false);
            }, 300))
          : antdMessage.warning(
              t('charging-grids.show.messages.label_and_date')
            )
      }
      footer={null}
    >
      <Form
        form={form}
        onFinish={(body) =>
          handleAddLabelAndDate({
            body,
            dispatchAPI,
            chargingGrid,
            setChargingGrid,
            setIsLabelModalOpen,
            message,
            collectiveOwnership,
            setCollectiveOwnership
          })
        }
      >
        <Form.Item
          name={['label']}
          label={t('charging-grids.show.modals.label.label')}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={['document_date']}
          label={t('charging-grids.show.modals.label.date')}
        >
          <DatePicker
            format="DD/MM/YYYY"
            open={isCalendarOpen}
            onClick={() => setIsCalendarOpen(true)}
            onChange={() => setIsCalendarOpen(false)}
          />
        </Form.Item>
        <Form.Item>
          <Row justify="end">
            {isEditLabel ? (
              <Button type="primary" onClick={() => setIsLabelModalOpen(false)}>
                {`${t('buttons.cancel')} `}
              </Button>
            ) : null}
            <Button type="primary" htmlType="submit">
              {`${t('buttons.save')} `}
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

AddLabelAndDateModal.propTypes = {
  isLabelModalOpen: PropTypes.bool.isRequired,
  setIsLabelModalOpen: PropTypes.func.isRequired,
  dispatchAPI: PropTypes.func.isRequired,
  chargingGrid: PropTypes.shape({
    _id: PropTypes.string,
    label: PropTypes.string,
    document_date: PropTypes.string
  }),
  setChargingGrid: PropTypes.func.isRequired,
  message: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  collectiveOwnership: PropTypes.shape({
    _id: PropTypes.string,
    charging_grid: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        version: PropTypes.number,
        date: PropTypes.string
      })
    )
  }),
  setCollectiveOwnership: PropTypes.func.isRequired,
  isEditLabel: PropTypes.bool
};

AddLabelAndDateModal.defaultProps = {
  chargingGrid: {},
  collectiveOwnership: {},
  isEditLabel: false
};
