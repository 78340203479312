import PropTypes from 'prop-types';
import { Layout } from 'antd';

const { Content } = Layout;

export const ContentCustom = ({ children, style }) => (
  <Content
    style={{
      margin: '8px 24px 8px',
      padding: 16,
      background: 'var(--clientColor)',
      minHeight: 'fit-content',
      borderRadius: 2,
      ...style
    }}
  >
    {children}
  </Content>
);

ContentCustom.propTypes = {
  style: PropTypes.shape({})
};

ContentCustom.defaultProps = {
  style: undefined
};
