/* eslint-disable consistent-return */
import React, { useState } from 'react';
import { Transfer, Button, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { useErrorMessage } from '../../../../../utils/errorMessage';

const formatInputs = (inputToFormat) => {
  const entries = [];

  if (inputToFormat.length > 0) {
    inputToFormat.forEach((inputGroup) => {
      const inputLabel = inputGroup.data.label;
      if (inputGroup.data.outputs === undefined) return [];
      const fields = Object.entries(inputGroup.data.outputs);
      fields.forEach((input) => {
        const modelEntry = input[0];

        const inputDefined = input[1] !== undefined;
        const inputTypeIsString =
          inputDefined && typeof input[1].type === 'string';
        const inputIsObject =
          inputDefined && input[1].type && input[1].type.length > 0;

        let type = '';
        let finalTest = false;
        if (!inputDefined && !inputTypeIsString && !inputIsObject) {
          type = 'other';
          finalTest = true;
        } else if (
          typeof input[1].type === 'string' ||
          (inputDefined && input[1].type && input[1].type.length > 0)
        ) {
          type =
            input[1] && typeof input[1].type === 'string'
              ? input[1].type
              : 'other';
          finalTest = true;
        } else if (!input[1].type) {
          type = 'other';
          finalTest = true;
        }

        if (finalTest) {
          const modelType = type;

          entries.push({
            key: `${inputLabel}|${modelEntry}`,
            title: `${inputLabel} - ${modelEntry} (${modelType})`,
            description: `description of content`
          });
        } else {
          const highLevelField = modelEntry;
          const complexType = input[1].type;
          Object.entries(complexType).forEach((subType) => {
            const lastField = `${highLevelField}.${subType[0]}`;
            const finalType =
              typeof subType[1].type === 'string' ? subType[1].type : 'other';

            entries.push({
              key: `${inputLabel}|${lastField}`,
              title: `${inputLabel} - ${lastField} (${finalType}) `,
              description: `description of content`
            });
          });
        }
      });
    });
  }
  return entries;
};

const ETLStepInputs = ({ inputs, stepInputs, setStepInputs, nextStep }) => {
  const { message } = useErrorMessage();
  const initialTargetKeys = formatInputs(stepInputs).map(
    (target) => target.key
  );
  const [targetKeys, setTargetKeys] = useState(initialTargetKeys || []);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const isBtnEnabled = targetKeys.length;

  const handleChange = (nextTargetKeys) => {
    try {
      setTargetKeys(nextTargetKeys);
      const targetKeysFormatted = nextTargetKeys.map((key) => {
        const newKey = key.split('|');
        return newKey;
      });

      const newinputs = inputs.map((inputGroup) => {
        // eslint-disable-next-line prefer-object-spread
        const STRInputGroup = JSON.stringify(inputGroup);
        const newinputGroup = { ...JSON.parse(STRInputGroup) };
        const newOutputs = {};

        targetKeysFormatted.forEach((line) => {
          if (line[0] === inputGroup.data.label) {
            const newKeyToAdd = line[1];
            const newInputToAdd = inputGroup.data.outputs[line[1]];
            newOutputs[newKeyToAdd] = newInputToAdd;
          }
        });
        newinputGroup.data.outputs = newOutputs;

        return newinputGroup;
      });
      setStepInputs(newinputs);
    } catch (e) {
      setStepInputs([]);
      message('load_fail');
    }
  };

  const handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  return (
    <Row>
      <Col span={24}>
        <Transfer
          showSearch
          dataSource={formatInputs(inputs)}
          titles={['Inputs disponibles', 'Valeurs à utiliser']}
          targetKeys={targetKeys}
          selectedKeys={selectedKeys}
          onChange={handleChange}
          onSelectChange={handleSelectChange}
          render={(item) => item.title}
          oneWay
          style={{ width: '100%', marginBottom: 16 }}
          listStyle={{ width: '100%', height: 300 }}
        />
        <Button type="add" block disabled={!isBtnEnabled} onClick={nextStep}>
          Valider
        </Button>
      </Col>
    </Row>
  );
};

ETLStepInputs.propTypes = {
  inputs: PropTypes.arrayOf({}).isRequired,
  stepInputs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      data: PropTypes.shape({}).isRequired,
      position: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired
      }).isRequired
    })
  ).isRequired,
  setStepInputs: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired
};

export default ETLStepInputs;
