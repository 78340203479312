import React from 'react';
import { Button, Row, Col } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { addWeeks, startOfWeek } from 'date-fns';

import { CalendarHeaderProps } from './types.tsx';
import { useTranslation } from 'react-i18next';
import { MonthName } from './MonthName.tsx';
import { SwitchModes } from '../SwitchModes.tsx';
import { SwitchWeekButtons } from './SwitchWeekButtons.tsx';

export const CalendarHeader: React.FunctionComponent<CalendarHeaderProps> = ({
  startWeek,
  setStartWeek,
  ISOWeekName,
  modes,
  mode,
  setMode,
  extraHeader
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Row justify="space-between" style={{ marginBottom: '20px' }}>
        <Col>
          <SwitchModes modes={modes} mode={mode} setMode={setMode} />
        </Col>
        <Col>{extraHeader}</Col>
        <Col
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <SwitchWeekButtons
            startWeek={startWeek}
            setStartWeek={setStartWeek}
          />
          <div style={{ alignSelf: 'center' }}>
            <MonthName startWeek={startWeek} ISOWeekName={ISOWeekName} />
          </div>
        </Col>
      </Row>
    </>
  );
};
