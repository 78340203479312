import { setNewUpdatedTotalValue } from './setNewUpdatedTotalValue';

/**
 * Sets initial row data for the charging grid.
 * @function
 * @param {Object} params - Function parameters
 * @param {Object} params.chargingGrid - The charging grid data
 * @param {Function} params.setCount - Function for setting the count of rows
 * @param {Function} params.setDataSource - Function for setting the data source
 * @returns {Array} Returns the initial rows data.
 */

export const setRowsData = ({ chargingGrid, setCount, setDataSource }) => {
  const updatedRowsData = chargingGrid.data;
  updatedRowsData[1] = setNewUpdatedTotalValue({ chargingGrid });

  const rowsData = updatedRowsData.map((data) => {
    const clonedData = { ...data };

    chargingGrid.columns.map((column) => {
      if (!clonedData[column.column.name]) {
        if (!column.is_text_column) {
          clonedData[column.column.name] = {
            value: 0,
            old_value: null
          };
        } else {
          clonedData[column.column.name] = {
            value: '',
            old_value: null
          };
        }
      }

      return null;
    });

    return clonedData;
  });

  const maxKey = Math.max(...chargingGrid.data.map((item) => item.key));

  setDataSource(rowsData);
  setCount(maxKey);

  return rowsData;
};
