import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Drawer, Form } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import useFields from './fields';
import { useGenerateFormItem } from '../../../utils/generateFormItem/generateFormItem';

const CreateTemplateDrawer = ({
  visible,
  setVisible,
  setRefreshData,
  refreshData
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const generateFormItem = useGenerateFormItem();
  const { fields, file } = useFields({});

  const onClose = () => {
    form.resetFields();
    setVisible(false);
  };

  const onFinishForm = async (values) => {
    try {
      const formData = new FormData();
      formData.append('file', file[0]);

      const { data } = await dispatchAPI('POST', {
        url: '/files',
        body: formData
      });

      const newValue = { ...values, file: data._id };

      const newFormData = new FormData();
      newFormData.append(
        'values',
        JSON.stringify({
          ...newValue
        })
      );

      await dispatchAPI('POST', {
        url: 'templates',
        body: newFormData
      });
      onClose();
      setRefreshData(!refreshData);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <Drawer
      destroyOnClose
      title={t('templates.form.title')}
      width={480}
      onClose={onClose}
      visible={visible}
      getContainer={false}
    >
      <Form
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
        form={form}
        onFinish={onFinishForm}
      >
        {fields.map((field) => generateFormItem('templates', field))}
        <Form.Item style={{ marginTop: 16 }}>
          <Button
            style={{ margin: '0 10px' }}
            type="link"
            danger
            onClick={onClose}
          >
            {`${t('buttons.cancel')} `}
            <CloseOutlined />
          </Button>
          <Button type="add" htmlType="submit">
            {`${t('buttons.save')} `}
            <CheckOutlined />
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default CreateTemplateDrawer;

CreateTemplateDrawer.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  setRefreshData: PropTypes.func,
  refreshData: PropTypes.bool
};

CreateTemplateDrawer.defaultProps = {
  visible: false,
  setVisible: null,
  setRefreshData: null,
  refreshData: false
};
