import { useTranslation } from 'react-i18next';

/**
 * useColumns Hook
 *
 * This hook generates table columns for the List view.
 *
 * @returns {Array} An array of column objects
 */

export const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('collective-ownerships.form.name'),
      key: 'name',
      dataIndex: 'name',
      sorter: true
    },
    {
      title: t('collective-ownerships.form.federation'),
      key: 'federation',
      dataIndex: 'federation',
      sorter: true
    },
    {
      title: t('collective-ownerships.form.address'),
      key: 'address',
      dataIndex: 'address',
      sorter: true
    }
  ];
};
