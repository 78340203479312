import { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Popover, Select, Switch, Table, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { CompactPicker } from 'react-color';
import { useErrorMessage } from '../../utils/errorMessage';
import { useIconArray } from './IconArray';
import usePanelContext from './ConfigurationContext';
import ButtonPanel from './ButtonPanel';

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  checkType,
  record,
  index,
  children,
  colorPicked,
  setColorPicked,
  isDisplayed,
  setIsDisplayed,
  selectType,
  iconValue,
  setIconValue,
  ...restProps
}) => {
  const { t } = useTranslation();
  const iconArray = useIconArray();
  let inputNode;

  const popoverContent = (
    <CompactPicker
      color={colorPicked}
      onChangeComplete={(color) => setColorPicked(color.hex)}
    />
  );

  switch (inputType) {
    case 'color':
      inputNode = (
        <Popover content={popoverContent} title={t('configurations.color')}>
          <Tag color={colorPicked}>{colorPicked}</Tag>
        </Popover>
      );
      break;
    case 'icon':
      inputNode = (
        <Select>
          {iconArray.map((icon) => (
            <Select.Option value={icon.label} key={icon._id}>
              {icon.value}
            </Select.Option>
          ))}
        </Select>
      );

      break;
    case 'display':
      inputNode = (
        <Switch
          defaultChecked={record.display || isDisplayed}
          onChange={() => setIsDisplayed(!isDisplayed)}
        />
      );
      break;
    default:
      inputNode = <Input />;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={[
            {
              required: true
            }
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const ConfigurationTable = ({ items, resourceColumns, resourceName }) => {
  const { t } = useTranslation();
  const { editItem, deleteItem, cancel, editingKey, setEditingKey, isEditing } =
    usePanelContext();
  const { message } = useErrorMessage();
  const [form] = Form.useForm();
  const [colorPicked, setColorPicked] = useState('#ababab');
  const [isDisplayed, setIsDisplayed] = useState(false);
  const [iconValue, setIconValue] = useState('');

  const edit = (record) => {
    form.setFieldsValue({
      label: '',
      ...record
    });
    setEditingKey(record._id);
    if (record?.color) setColorPicked(record.color);
  };

  const setValues = ({ color, icon }) => {
    form.setFieldsValue({
      color: colorPicked || color,
      display: isDisplayed,
      iconValue: iconValue || icon
    });
  };

  const save = async (record) => {
    setValues(record);
    try {
      const row = await form.validateFields();
      editItem(row, record._id, resourceName);
      setEditingKey('');
    } catch (e) {
      message(e);
    }
  };

  const columns = [
    ...resourceColumns,

    {
      title: t(''),
      dataIndex: 'operation',
      width: '15%',
      align: 'right',
      render: (_, record) => {
        const editable = isEditing(record);
        if (record.reserved !== true) {
          return (
            <ButtonPanel
              editingKey={editingKey}
              editable={editable}
              saveItem={() => save(record)}
              edit={() => edit(record)}
              cancel={cancel}
              deleteItem={() => deleteItem(record, resourceName)}
            />
          );
        }
        return false;
      }
    }
  ];

  const mergedColumns = columns.map((col) => {
    if (col?.editable) {
      const createInputType = (dataIndex) => {
        switch (dataIndex[0]) {
          case 'icon':
            return 'icon';
          case 'color':
            return 'color';
          case 'display':
            return 'display';
          default:
            return 'text';
        }
      };

      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: createInputType(col.dataIndex),
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
          colorPicked,
          setColorPicked,
          isDisplayed,
          setIsDisplayed,
          iconValue,
          setIconValue
        })
      };
    }
    return col;
  });
  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell
          }
        }}
        bordered={false}
        dataSource={items}
        columns={mergedColumns}
        style={{ overflowX: 'auto', overflowY: 'visible' }}
        rowClassName="editable-row"
        pagination={false}
      />
    </Form>
  );
};

EditableCell.propTypes = {
  editing: PropTypes.bool,
  dataIndex: PropTypes.string,
  title: PropTypes.string,
  inputType: PropTypes.string,
  checkType: PropTypes.string,
  record: PropTypes.string,
  index: PropTypes.string,
  setColorPicked: PropTypes.string,
  colorPicked: PropTypes.string,
  isDisplayed: PropTypes.bool,
  setIsDisplayed: PropTypes.bool,
  iconValue: PropTypes.string,
  setIconValue: PropTypes.string,
  selectType: PropTypes.string
};

EditableCell.defaultProps = {
  setIconValue: undefined,
  iconValue: undefined,
  setIsDisplayed: undefined,
  isDisplayed: undefined,
  colorPicked: undefined,
  setColorPicked: undefined,
  record: undefined,
  inputType: undefined,
  title: undefined,
  dataIndex: undefined,
  editing: undefined,
  selectType: undefined,
  checkType: undefined,
  index: undefined
};

ConfigurationTable.propTypes = {
  items: PropTypes.string.isRequired,
  resourceColumns: PropTypes.shape({}).isRequired,
  resourceName: PropTypes.string.isRequired
};

export default ConfigurationTable;
