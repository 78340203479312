import { Button, Form, Row, InputNumber, Modal, Checkbox, Col } from 'antd';
import PropTypes from 'prop-types';
import { handleAddRows } from './utils/handleAddRows';

/**
 * AddLotModal Component
 *
 * This component displays a modal for adding lots to the charging grid.
 *
 * @component
 * @param {object} props - The props for the component.
 * @param {boolean} props.isLotModalOpen - Flag indicating if the modal is open.
 * @param {function} props.setIsLotModalOpen - Function to set the modal open state.
 * @param {number} props.count - The count of lots.
 * @param {array} props.dataSource - The data source for the table.
 * @param {function} props.t - i18n translation function.
 * @param {function} props.dispatchAPI - Function for making API calls.
 * @param {object} props.chargingGrid - The charging grid data.
 * @param {string} props.chargingGrid._id - The ID of the charging grid.
 * @param {function} props.message - Function for displaying messages.
 * @param {object} props.collectiveOwnership - The collective ownership data.
 * @param {string} props.collectiveOwnership._id - The ID of the collective ownership.
 * @param {function} props.setChargingGrid - Function to set the charging grid data.
 * @param {object} props.tableForm - The form for the table.
 * @param {function} props.tableForm.setFieldsValue - Function to set form fields value.
 * @param {function} props.setIsVersionButton - Function to set the version button flag.
 * @param {function} props.setIsFinalVersion - Function to set the final version flag.
 * @returns {JSX.Element} React component
 */

export const AddLotModal = ({
  isLotModalOpen,
  setIsLotModalOpen,
  chargingGrid,
  t,
  count,
  dataSource,
  dispatchAPI,
  message,
  collectiveOwnership,
  setChargingGrid,
  setIsVersionButton,
  setIsFinalVersion,
  setCollectiveOwnership
}) => {
  const [form] = Form.useForm();

  const setInitialValue = () => {
    const parseValue = (value) => {
      if (typeof value === 'string') {
        return parseInt(value.replace('bis', '').trim(), 10);
      }
      if (typeof value === 'number') {
        return value;
      }
      return null;
    };

    if (chargingGrid.data[chargingGrid.data.length - 1].Lot.value !== 'Total') {
      let maxValue = -Infinity;

      chargingGrid.data.forEach((item) => {
        const value = parseValue(item.Lot.value);
        if (value > maxValue) {
          maxValue = value;
        }
      });
      return maxValue + 1;
    }

    return 1;
  };

  form.setFieldsValue({
    from: setInitialValue(),
    row_number: 1
  });

  return (
    <Modal
      forceRender
      title={t('charging-grids.show.modals.title.add')}
      open={isLotModalOpen}
      onCancel={() => setIsLotModalOpen(false)}
      footer={null}
    >
      <Form
        form={form}
        onFinish={(body) => {
          handleAddRows({
            count,
            dataSource,
            dispatchAPI,
            chargingGrid,
            message,
            t,
            collectiveOwnership,
            setChargingGrid,
            body,
            setIsVersionButton,
            setIsLotModalOpen,
            setIsFinalVersion,
            setCollectiveOwnership
          });
        }}
      >
        <Row justify="space-between">
          <Col>
            <Form.Item
              name={['row_number']}
              label={t('charging-grids.show.modals.label.number_of_lot')}
            >
              <InputNumber />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name={['is_duplicate_lot']} valuePropName="checked">
              <Checkbox>
                {t('charging-grids.show.modals.label.is_duplicate_lot')}
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name={['from']}
          label={t('charging-grids.show.modals.label.from')}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item>
          <Row justify="end" gutter={[6, 6]}>
            <Button type="cancel" onClick={() => setIsLotModalOpen(false)}>
              {`${t('buttons.cancel')} `}
            </Button>
            <Button type="primary" htmlType="submit">
              {`${t('buttons.save')} `}
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

AddLotModal.propTypes = {
  isLotModalOpen: PropTypes.bool.isRequired,
  setIsLotModalOpen: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  dataSource: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  t: PropTypes.func.isRequired,
  dispatchAPI: PropTypes.func.isRequired,
  chargingGrid: PropTypes.shape({
    _id: PropTypes.string,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        Lot: PropTypes.shape({
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        })
      })
    )
  }),
  message: PropTypes.func.isRequired,
  collectiveOwnership: PropTypes.shape({
    _id: PropTypes.string
  }).isRequired,
  setChargingGrid: PropTypes.func.isRequired,
  tableForm: PropTypes.shape({
    setFieldsValue: PropTypes.func.isRequired
  }).isRequired,
  setIsVersionButton: PropTypes.func.isRequired,
  setIsFinalVersion: PropTypes.func.isRequired,
  setCollectiveOwnership: PropTypes.func.isRequired
};

AddLotModal.defaultProps = {
  chargingGrid: {}
};
