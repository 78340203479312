import { useCallback, useEffect, useState, forwardRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, Row, Spin } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { ContentCustom } from '../ContentCustom/ContentCustom';
import { PageHeaderCustom } from '../PageHeader/PageHeader';
import { useGenerateFormItem } from '../../utils/generateFormItem/generateFormItem';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../utils/constants/formLayout';
import FilesManager from './FilesManager/FilesManager';
import { handleFormDataWithFiles } from './utils/handleFormDataWithFiles';
import { handleFileActionsOnFetch } from './utils/handleFileActionsOnFetch';
import { checkDraggerTypes } from './utils/checkDraggerTypes';

/**
 * `CreateUpdateContainer` is a container for creating and updating resources, which includes form controls and various handlers for creating and updating resources.
 *
 * @component
 * @prop {string} purpose - Defines whether the purpose of the form is to 'edit' or 'create'.
 * @prop {Array} fields - An array of field objects for the form.
 * @prop {string} baseUrl - Base URL for the API endpoints.
 * @prop {string} resource - The name of the resource being created or edited.
 * @prop {boolean} [loadingFields=false] - A flag to determine whether the form fields are being loaded.
 * @prop {Object} [config={}] - A configuration object that provides handlers for getting, creating, and updating resources.
 * @prop {ReactElement} [formExtra=null] - Any extra form elements to be added.
 * @prop {string} [tradKey=null] - Key used for translation.
 * @prop {string} [submitLabel=null] - Label for the form submission button.
 * @prop {Function} [customSubmit=null] - A custom function to handle form submission.
 * @prop {boolean} [isParentLoading=false] - A flag to determine if the parent component is being loaded.
 * @prop {boolean} [withFilesManager=true] - A flag to determine whether to use a file manager in the form.
 * @ref
 */

export const CreateUpdateContainer = forwardRef(
  (
    {
      purpose,
      fields,
      loadingFields,
      resource,
      baseUrl,
      config,
      formExtra,
      tradKey,
      submitLabel,
      customSubmit,
      isParentLoading,
      withFilesManager,
      withEnums
    },
    ref
  ) => {
    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { message } = useErrorMessage();
    const { dispatchAPI } = useAuthContext();

    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [draggerFilesList, setDraggerFilesList] = useState([]);
    const [fieldsFilesList, setFieldsFileList] = useState([]);
    const [filesConfiguration, setFilesConfiguration] = useState([]);
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [draggerFilesKeys, setDraggerFilesKeys] = useState([]);
    const [form] = Form.useForm();
    const { onGetResource, onCreateResource, onUpdateResource } = config;

    const getResourceFilesKeys = async () => {
      try {
        const { data } = await dispatchAPI('GET', {
          url: `${baseUrl}/enums`
        });
        setDraggerFilesKeys(
          data.fileKeys
            .filter((enumItem) => enumItem.source === 'dragger')
            .map((enumItem) => enumItem.key)
        );
        return null;
      } catch (e) {
        return message(e);
      }
    };

    const updateResource = async (body, files) => {
      setIsSubmitting(true);
      const formData = new FormData();

      handleFormDataWithFiles(
        files,
        draggerFilesList,
        formData,
        filesConfiguration,
        purpose
      );

      const values =
        onUpdateResource && onUpdateResource.setBody
          ? onUpdateResource.setBody(body)
          : body;

      formData.append(
        'values',
        JSON.stringify({
          ...values
        })
      );

      try {
        await dispatchAPI('PATCH', {
          url: `${baseUrl}/${id}`,
          body: formData
        });
        navigate(-1);
      } catch (e) {
        setIsSubmitting(false);
        message(e);
      }
    };

    const createResource = async (body, files) => {
      setIsSubmitting(true);
      const formData = new FormData();

      handleFormDataWithFiles(
        files,
        draggerFilesList,
        formData,
        filesConfiguration,
        purpose
      );

      const values =
        onCreateResource && onCreateResource.setBody
          ? onCreateResource.setBody(body)
          : body;

      formData.append(
        'values',
        JSON.stringify({
          ...values
        })
      );

      try {
        await dispatchAPI('POST', {
          url: `${baseUrl}`,
          body: formData
        });
        navigate(-1);
      } catch (e) {
        setIsSubmitting(false);
        message(e);
      }
    };

    const deleteFile = async (fileID) => {
      try {
        await dispatchAPI('PATCH', {
          url: `${baseUrl}/${id}/${fileID}`
        });
      } catch (e) {
        message(e);
      }
    };

    const getResource = useCallback(async () => {
      setIsLoading(true);
      try {
        const { data } = await dispatchAPI('GET', {
          url: `${baseUrl}/${id}?populate=documents.file`
        });
        if (data?.documents?.length) {
          handleFileActionsOnFetch(
            data,
            setFieldsFileList,
            setDraggerFilesList,
            setFilesConfiguration,
            dispatchAPI,
            message
          );
        }
        form.setFieldsValue(
          onGetResource && onGetResource.setFields
            ? onGetResource.setFields(data)
            : data
        );
      } catch (e) {
        message(e);
      }
      setIsLoading(false);
    }, [purpose, id, loadingFields, baseUrl]);

    useEffect(() => {
      if (!loadingFields) {
        setIsLoading(true);
        (async () => {
          if (purpose === 'edit' && id) {
            await getResource();
          }
          if (withEnums) {
            await getResourceFilesKeys();
          }
          setIsLoading(false);
        })();
      }
    }, [getResource]);

    const handleSubmit = async (values) => {
      const extractedFileKeys = filesToUpload.map(
        (fileObject) => fileObject.file
      );
      const boolean = checkDraggerTypes(draggerFilesList, filesConfiguration);

      if (!boolean && withFilesManager) {
        message(t('missing_types'));
      } else {
        setFieldsFileList((prevList) => [...prevList, ...extractedFileKeys]);

        if (customSubmit) customSubmit(values, extractedFileKeys);
        else {
          if (purpose === 'edit')
            await updateResource(values, extractedFileKeys);
          if (purpose === 'create')
            await createResource(values, extractedFileKeys);
        }
      }
    };

    const generateFieldsMemoized = useCallback(
      useGenerateFormItem(
        fieldsFilesList,
        filesConfiguration,
        setFilesConfiguration,
        purpose,
        deleteFile,
        setFilesToUpload
      ),
      [fieldsFilesList, filesConfiguration]
    );

    return (
      <>
        <PageHeaderCustom title={t(`${resource}.form.title.${purpose}`)} />
        <ContentCustom>
          <Spin spinning={isLoading || isParentLoading}>
            <Form
              ref={ref}
              {...formItemLayout}
              onFinish={handleSubmit}
              form={form}
            >
              {fields.map((field) =>
                generateFieldsMemoized(tradKey || resource, field)
              )}
              {formExtra}
              {withFilesManager && (
                <FilesManager
                  filesList={draggerFilesList}
                  setFilesList={setDraggerFilesList}
                  filesKeys={draggerFilesKeys}
                  filesConfiguration={filesConfiguration}
                  setFilesConfiguration={setFilesConfiguration}
                  purpose={purpose}
                  deleteFile={deleteFile}
                />
              )}
              <Form.Item {...tailFormItemLayout}>
                <Row justify="end">
                  <Button
                    style={{ margin: '0 8px' }}
                    type="link"
                    danger
                    onClick={() => navigate(-1)}
                  >
                    {`${t('buttons.cancel')} `}
                    <CloseOutlined />
                  </Button>
                  <Button type="add" htmlType="submit" loading={isSubmitting}>
                    {`${t(submitLabel || 'buttons.save')} `}
                    <CheckOutlined />
                  </Button>
                </Row>
              </Form.Item>
            </Form>
          </Spin>
        </ContentCustom>
      </>
    );
  }
);

CreateUpdateContainer.propTypes = {
  purpose: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  baseUrl: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  loadingFields: PropTypes.bool,
  config: PropTypes.shape({
    onGetResource: PropTypes.shape({
      setFields: PropTypes.func
    }),
    onCreateResource: PropTypes.shape({
      setBody: PropTypes.func
    }),
    onUpdateResource: PropTypes.shape({
      setBody: PropTypes.func
    })
  }),
  formExtra: PropTypes.element,
  tradKey: PropTypes.string,
  submitLabel: PropTypes.string,
  customSubmit: PropTypes.func,
  isParentLoading: PropTypes.bool,
  withFilesManager: PropTypes.bool,
  withEnums: PropTypes.bool
};

CreateUpdateContainer.defaultProps = {
  config: {},
  loadingFields: false,
  formExtra: null,
  tradKey: null,
  submitLabel: null,
  customSubmit: null,
  isParentLoading: false,
  withFilesManager: true,
  withEnums: true
};
