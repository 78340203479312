/**
 * Updates the total value in the charging grid data by comparing it with the desired value.
 * If the values are different and not of type string, the total value is wrapped in a red-colored div.
 * @function
 * @param {Object} options - The options object.
 * @param {Object} options.chargingGrid - The charging grid object.
 * @returns {Object} - The updated total value object.
 */
export const setNewUpdatedTotalValue = ({ chargingGrid }) => {
  const diseredValue = chargingGrid.data[0];
  const totalValue = chargingGrid.data[1];

  Object.keys(totalValue).forEach((key) => {
    if (
      totalValue[key]?.value !== diseredValue[key]?.value &&
      typeof totalValue[key].value !== 'string'
    ) {
      totalValue[key].value = (
        <div style={{ color: 'red' }}>{totalValue[key].value}</div>
      );
    }
  });

  return totalValue;
};
