import { getChargingGrid } from './getChargingGrid';

/**
 * Generates a file for the charging grid.
 * @async
 * @function
 * @param {Object} params - Function parameters
 * @param {boolean} params.isFinalVersion - Indicates if it's the final version
 * @param {Object} params.chargingGrid - The charging grid object
 * @param {Function} params.dispatchAPI - Function for dispatching API requests
 * @param {Function} params.message - Function for displaying messages
 * @param {Object} params.collectiveOwnership - The collective ownership object
 * @param {Function} params.setChargingGrid - Function for setting charging grid data
 * @param {Function} params.setIsVersionButton - Function for setting version button state
 * @param {Object} params.template - The file template
 * @param {Function} params.downloadDocument - Function for downloading the document
 * @returns {Promise<void>} - A Promise that resolves when the file generation is complete
 */

export const generateFile = async ({
  isFinalVersion,
  chargingGrid,
  dispatchAPI,
  message,
  collectiveOwnership,
  setChargingGrid,
  setIsVersionButton,
  template,
  downloadDocument
}) => {
  try {
    const version = isFinalVersion ? 'final' : 'intermediate';

    if (chargingGrid.files[version].new_file) {
      const { data } = await dispatchAPI('GET', {
        url: `/files/generate/charging_grid/${chargingGrid._id}/${template[0]._id}?isFinalVersion=${isFinalVersion}`
      });

      setTimeout(() => {
        downloadDocument({
          _id: data._id,
          metadata: data.metadata,
          contentType: data.contentType
        });
      }, [500]);

      getChargingGrid({
        collectiveOwnership,
        setChargingGrid,
        setIsVersionButton
      });
    } else {
      const { data } = await dispatchAPI('GET', {
        url: `/files/data/${chargingGrid.files[version].file}`
      });

      downloadDocument({
        _id: data._id,
        metadata: data.metadata,
        contentType: data.contentType
      });
    }
  } catch (e) {
    if (e.response) message(e.response);
  }
};
