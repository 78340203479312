/**
 * Checks if the current operation is 'edit' and if the input is a file.
 * If both conditions are true, filters and sets the file list based on the provided file key.
 *
 * @function
 * @param {string} purpose - The purpose of the operation ('edit' or other values).
 * @param {boolean} isFileInput - Flag to check if the input is of type file.
 * @param {Array} fieldsFilesList - The list of files in the fields.
 * @param {Array} name - An array representing the name of the field associated with the file.
 * @param {function} setFileList - Function to set the file list.
 * @returns {void}
 */

export const setDraggerFilesList = (
  purpose,
  isFileInput,
  fieldsFilesList,
  name,
  setFileList
) => {
  if (purpose === 'edit' && isFileInput) {
    const files = fieldsFilesList.filter(
      (fileItem) => fileItem.fileKey === name.join('')
    );
    if (files.length) {
      const updatedFileList = files.map((file) => ({
        rawFile: file.rawFile,
        file: file.file,
        url: file.file.url,
        documentID: file._id
      }));
      setFileList(updatedFileList);
    }
  }
};
