import React from 'react';
import PropTypes from 'prop-types';
import { Button, Popconfirm, Row } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  SaveOutlined,
  CloseOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const ButtonPanel = ({
  editingKey,
  editable,
  saveItem,
  edit,
  cancel,
  deleteItem
}) => {
  const { t } = useTranslation();
  return editable ? (
    <Row align="middle" gutter={4}>
      <Button
        onClick={(event) => {
          event.stopPropagation();
          saveItem();
        }}
        type="add"
      >
        {t('buttons.save')}
        <SaveOutlined />
      </Button>
      <Popconfirm
        title={t('settings.messages.confirm-deletion')}
        onConfirm={cancel}
        placement="topRight"
      >
        <Button
          onClick={(event) => {
            event.stopPropagation();
          }}
          type="link"
        >
          {t('buttons.cancel')}
          <CloseOutlined />
        </Button>
      </Popconfirm>
    </Row>
  ) : (
    <Row align="middle" gutter={4}>
      <Button
        type="link"
        disabled={editingKey !== ''}
        onClick={(event) => {
          event.stopPropagation();
          edit();
        }}
      >
        <EditOutlined />
      </Button>
      <Button
        type="link"
        disabled={editingKey !== ''}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <Popconfirm
          title={t('settings.messages.delete-line-confirm')}
          onConfirm={deleteItem}
          placement="topRight"
        >
          <DeleteOutlined
            type="delete"
            style={{ color: 'var(--errorColor)' }}
          />
        </Popconfirm>
      </Button>
    </Row>
  );
};

export default ButtonPanel;

ButtonPanel.propTypes = {
  editable: PropTypes.bool.isRequired,
  editingKey: PropTypes.string.isRequired,
  saveItem: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired
};
