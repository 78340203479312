import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { SwitchWeekButtonsProps } from './types';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { addWeeks, startOfWeek } from 'date-fns';

export const SwitchWeekButtons: React.FunctionComponent<
  SwitchWeekButtonsProps
> = ({ setStartWeek, startWeek }) => {
  const { t } = useTranslation();
  return (
    <div style={{ display: 'flex', marginRight: '20px' }}>
      <Button onClick={() => setStartWeek(startOfWeek(new Date()))}>
        {t('calendar.buttons.today')}
      </Button>
      <div style={{ display: 'flex', padding: '0 10px' }}>
        <Button
          style={{ margin: '0 5px' }}
          onClick={() => setStartWeek(addWeeks(startWeek, -1))}
        >
          <LeftOutlined />
        </Button>
        <Button
          style={{ margin: '0 5px' }}
          onClick={() => setStartWeek(addWeeks(startWeek, 1))}
        >
          <RightOutlined />
        </Button>
      </div>
    </div>
  );
};
