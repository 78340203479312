import { createFileFromJSON } from './createFileFromJSON';

/**
 * Handles file actions on data fetch.
 *
 * @async
 * @function
 * @param {Object} data - The data from the fetch operation.
 * @param {Function} setFieldsFileList - The setter function for fields file list state.
 * @param {Function} setDraggerFilesList - The setter function for dragger files list state.
 * @param {Function} setFilesConfiguration - The setter function for files configuration state.
 * @param {Function} dispatchAPI - The dispatch function to make API requests.
 * @param {Function} message - The function to display messages.
 * @returns {Promise<void>} A promise that resolves when all file actions have been handled.
 * @throws {Error} If there is an error during file creation.
 */
export const handleFileActionsOnFetch = async (
  data,
  setFieldsFileList,
  setDraggerFilesList,
  setFilesConfiguration,
  dispatchAPI,
  message
) => {
  const fieldsDocuments = data.documents.filter(
    (document) => document.source === 'field'
  );

  const normalizedFieldsDocuments = fieldsDocuments.map(async (document) => ({
    ...document,
    rawFile: document.file,
    file: await createFileFromJSON(document, dispatchAPI, message)
  }));
  const resolvedFieldsDocuments = await Promise.all(normalizedFieldsDocuments);

  setFieldsFileList(resolvedFieldsDocuments);

  const draggerDocuments = data.documents.filter(
    (document) => document.source === 'dragger'
  );
  const draggerNormalizedDocuments = draggerDocuments.map((document) => ({
    ...document,
    name: document.file.metadata.originalName
  }));
  setDraggerFilesList(draggerNormalizedDocuments);

  setFilesConfiguration([
    ...draggerDocuments.map((document) => ({
      fileKey: document.fileKey,
      name: document.file.metadata.originalName,
      source: document.source
    })),
    ...fieldsDocuments.map((document) => ({
      fileKey: document.fileKey,
      name: document.file.metadata.originalName,
      source: document.source,
      id: document.file._id,
      type: document.type
    }))
  ]);
};
