import {
  add,
  isSameHour,
  addHours,
  startOfDay,
  startOfWeek,
  format,
} from 'date-fns';
import { WeekObject, EventsObject, GetWeekDates, GenericEvent } from '../types';
import { useTranslation } from 'react-i18next';

export const getDayHoursEvents = <T extends GenericEvent>(
  value: GetWeekDates,
  weekObject: WeekObject<T> | undefined,
  hourStart: number,
  hourEnd: number,
  noAllDayRow: boolean
) => {
  const { t } = useTranslation();
  const ALL_DAY_EVENT = noAllDayRow ? undefined : hourStart;
  const events: EventsObject<T>[] = [];
  for (let i = hourStart; i < hourEnd; i++) {
    const startDate = add(startOfDay(startOfWeek(value.startDate)), {
      days: 1
    });
    const hour = addHours(startDate, i - 1);
    events.push({
      id: i,
      hourObject: hour,
      hour:
        i != ALL_DAY_EVENT
          ? t(`calendar.hours.${format(hour, 'hh a')}`)
          : t('calendar.hours.all-day'),
      Monday:
        weekObject?.monday &&
        weekObject?.monday.filter((e) => {
          return e.allDay ? i === ALL_DAY_EVENT : isSameHour(e.startTime, hour);
        }),
      Tuesday:
        weekObject?.tuesday &&
        weekObject?.tuesday.filter((e) => {
          return e.allDay
            ? i === ALL_DAY_EVENT
            : isSameHour(e.startTime, add(hour, { days: 1 }));
        }),
      Wednesday:
        weekObject?.wednesday &&
        weekObject?.wednesday.filter((e) => {
          return e.allDay
            ? i === ALL_DAY_EVENT
            : isSameHour(e.startTime, add(hour, { days: 2 }));
        }),
      Thursday:
        weekObject?.thursday &&
        weekObject?.thursday.filter((e) => {
          return e.allDay
            ? i === ALL_DAY_EVENT
            : isSameHour(e.startTime, add(hour, { days: 3 }));
        }),
      Friday:
        weekObject?.friday &&
        weekObject?.friday.filter((e) => {
          return e.allDay
            ? i === ALL_DAY_EVENT
            : isSameHour(e.startTime, add(hour, { days: 4 }));
        }),
      Saturday:
        weekObject?.saturday &&
        weekObject?.saturday.filter((e) => {
          return e.allDay
            ? i === ALL_DAY_EVENT
            : isSameHour(e.startTime, add(hour, { days: 5 }));
        }),
      Sunday:
        weekObject?.sunday &&
        weekObject?.sunday.filter((e) => {
          return e.allDay
            ? i === ALL_DAY_EVENT
            : isSameHour(e.startTime, add(hour, { days: 6 }));
        })
    });
  }
  return events;
};
