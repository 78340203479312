import { updateChargingGrids } from './updateCollectiveOwnership';

/**
 * Handles the deletion of a column.
 * @function
 * @param {Object} params - Function parameters
 * @param {string} params.columnId - The ID of the column to be deleted
 * @param {Object} params.charging_grid - The charging grid object
 * @param {Function} params.dispatchAPI - Function for dispatching API requests
 * @param {Function} params.message - Function for displaying messages
 * @param {Function} params.setChargingGrid - Function for setting charging grid data
 * @param {Function} params.setIsFinalVersion - Function for setting final version status
 * @returns {null}
 */

export const handleDeleteColumn = async ({
  columnId,
  charging_grid,
  dispatchAPI,
  message,
  setChargingGrid,
  setIsFinalVersion,
  collectiveOwnership,
  setCollectiveOwnership
}) => {
  try {
    const { data } = await dispatchAPI('PATCH', {
      url: `/charging-grids/${charging_grid._id}?type=delete_column&column_id=${columnId}`
    });

    updateChargingGrids({
      collectiveOwnership,
      chargingGrid: data,
      setCollectiveOwnership
    });
    setChargingGrid(data);
  } catch (e) {
    message(e);
  }
  setIsFinalVersion(false);
};
