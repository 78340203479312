import { Button, Col, Row, Select, Switch, Tooltip, Typography } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import PropTypes from 'prop-types';
import { getChargingGrid } from '../utils/getChargingGrid';
import { createNewChargingGrid } from '../utils/createNewChargingGrid';
import { deleteChargingGrid } from '../utils/deleteChargingGrid';
import { handleSaveAllTable } from '../utils/handleSaveAllTable';
import { handleEditAllTable } from '../utils/handleEditAllTable';

const { Text } = Typography;
const { Option } = Select;

export const ActionButtons = ({
  isLoading,
  setIsFinalVersion,
  collectiveOwnership,
  setChargingGrid,
  setIsVersionButton,
  chargingGrid,
  isVersionButton,
  dispatchAPI,
  setCollectiveOwnership,
  message,
  setSelectedRowKeys,
  t,
  isFinalVersion,
  setIsLotModalOpen,
  setIsEditLotModalOpen,
  setIsMergeLotModalOpen,
  setIsDivisionLotModalOpen,
  setIsColumnModalOpen,
  setDuplicateColumn,
  selectedRowKeys,
  setIsLabelModalOpen,
  form,
  dataSource,
  setEditTable,
  editTable,
  handleSaveAllTableButtonRef,
  handleEditAllTableButtonRef,
  setSelectedRowKey
}) => (
  <Col xs={24} xxl={24}>
    <Row align="middle" justify="space-between" gutter={[12, 12]}>
      <Col>
        <Row gutter={[12, 12]} align="middle">
          <Col>
            <Row gutter={[6, 6]} align="middle">
              <Col>
                <Text>{t('charging-grids.show.filter_label.version')}</Text>
              </Col>
              <Col>
                <Select
                  loading={isLoading}
                  onChange={(_, option) => {
                    setIsFinalVersion(false);
                    getChargingGrid({
                      chargingGrid: option?.data,
                      collectiveOwnership,
                      setChargingGrid,
                      setIsVersionButton
                    });
                  }}
                  value={chargingGrid._id}
                  showSearch
                  allowClear
                  style={{ minWidth: 150 }}
                  filterOption={(input, option) =>
                    (option?.value ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {(collectiveOwnership.charging_grid || []).map((grid) => (
                    <Option key={grid._id} value={grid._id} data={grid}>
                      {`V${grid.version} ${moment(grid.date).format(
                        'DD/MM/YYYY'
                      )}`}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              {isVersionButton ? (
                <Button
                  type="primary"
                  onClick={() => {
                    createNewChargingGrid({
                      dispatchAPI,
                      chargingGrid,
                      setCollectiveOwnership,
                      collectiveOwnership,
                      setChargingGrid,
                      message,
                      setIsVersionButton,
                      setIsLabelModalOpen
                    });
                    setSelectedRowKeys([]);
                  }}
                  style={{ marginRight: 6 }}
                >
                  <PlusOutlined />
                  {t('charging-grids.show.buttons.create_version')}
                </Button>
              ) : null}
              {isVersionButton && chargingGrid.version !== 1 ? (
                <Tooltip
                  title={t('charging-grids.show.buttons.delete_version')}
                >
                  <Button
                    type="link"
                    danger
                    icon={<DeleteOutlined />}
                    onClick={() =>
                      deleteChargingGrid({
                        chargingGrid,
                        dispatchAPI,
                        collectiveOwnership,
                        setChargingGrid,
                        setCollectiveOwnership,
                        setIsVersionButton
                      })
                    }
                  />
                </Tooltip>
              ) : null}
            </Row>
          </Col>
          {chargingGrid.version !== 1 ? (
            <Col>
              <Row align="middle">
                {t('charging-grids.show.final_version')}
                <Switch
                  checked={isFinalVersion}
                  defaultChecked={isFinalVersion}
                  onChange={() => setIsFinalVersion(!isFinalVersion)}
                  style={{ marginLeft: 6 }}
                />
              </Row>
            </Col>
          ) : null}
        </Row>
      </Col>
      <Row gutter={[6, 6]}>
        {!editTable ? (
          <Col>
            <Button
              type="primary"
              ref={handleEditAllTableButtonRef}
              onClick={() =>
                handleEditAllTable({
                  setEditTable,
                  editTable,
                  setSelectedRowKey,
                  dataSource,
                  form
                })
              }
            >
              {t('charging-grids.show.buttons.edit_table')}
            </Button>
          </Col>
        ) : (
          <>
            <Col>
              <Button type="link" danger onClick={() => setEditTable(false)}>
                {t('charging-grids.show.buttons.cancel')}
              </Button>
            </Col>

            <Col>
              <Button
                type="primary"
                ref={handleSaveAllTableButtonRef}
                onClick={() =>
                  handleSaveAllTable({
                    form,
                    dataSource,
                    dispatchAPI,
                    chargingGrid,
                    collectiveOwnership,
                    setCollectiveOwnership,
                    setChargingGrid,
                    setEditTable,
                    message
                  })
                }
              >
                {t('charging-grids.show.buttons.save')}
              </Button>
            </Col>
          </>
        )}
        <Col>
          <Button
            type="primary"
            onClick={() => {
              setDuplicateColumn(null);
              setIsColumnModalOpen(true);
            }}
          >
            {t('charging-grids.show.buttons.add_column')}
          </Button>
        </Col>
        <Col>
          <Button onClick={() => setIsLotModalOpen(true)} type="primary">
            {t('charging-grids.show.buttons.add_lot')}
          </Button>
        </Col>
        <Col>
          <Button onClick={() => setIsEditLotModalOpen(true)} type="primary">
            {t('charging-grids.show.buttons.edit_lot')}
          </Button>
        </Col>
        {selectedRowKeys.length > 1 ? (
          <Col>
            <Button onClick={() => setIsMergeLotModalOpen(true)} type="primary">
              {t('charging-grids.show.buttons.merge')}
            </Button>
          </Col>
        ) : null}
        {selectedRowKeys.length === 1 ? (
          <Col>
            <Button
              onClick={() => setIsDivisionLotModalOpen(true)}
              type="primary"
            >
              {t('charging-grids.show.buttons.divide')}
            </Button>
          </Col>
        ) : null}
      </Row>
    </Row>
  </Col>
);

ActionButtons.propTypes = {
  chargingGrid: PropTypes.shape({
    label: PropTypes.string,
    document_date: PropTypes.string,
    _id: PropTypes.string,
    version: PropTypes.number
  }),
  isFinalVersion: PropTypes.bool,
  dispatchAPI: PropTypes.func,
  message: PropTypes.func,
  collectiveOwnership: PropTypes.shape({
    _id: PropTypes.string,
    charging_grid: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        version: PropTypes.number,
        date: PropTypes.string
      })
    )
  }),
  setChargingGrid: PropTypes.func,
  setIsVersionButton: PropTypes.func,
  t: PropTypes.func,
  setIsLotModalOpen: PropTypes.func,
  setIsEditLotModalOpen: PropTypes.func,
  setIsMergeLotModalOpen: PropTypes.func,
  setIsDivisionLotModalOpen: PropTypes.func,
  setIsColumnModalOpen: PropTypes.func,
  setDuplicateColumn: PropTypes.func,
  selectedRowKeys: PropTypes.arrayOf(PropTypes.string),
  setSelectedRowKeys: PropTypes.func,
  isLoading: PropTypes.bool,
  setIsFinalVersion: PropTypes.func,
  setCollectiveOwnership: PropTypes.func,
  isVersionButton: PropTypes.bool,
  setIsLabelModalOpen: PropTypes.func,
  form: PropTypes.shape({
    validateFields: PropTypes.func
  }),
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number
    })
  ),
  setEditTable: PropTypes.func,
  editTable: PropTypes.bool,
  handleSaveAllTableButtonRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  }),
  handleEditAllTableButtonRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  }),
  setSelectedRowKey: PropTypes.func
};

ActionButtons.defaultProps = {
  chargingGrid: {
    label: '',
    document_date: ''
  },
  isFinalVersion: false,
  dispatchAPI: () => {},
  message: () => {},
  collectiveOwnership: {},
  setChargingGrid: () => {},
  setIsVersionButton: () => {},
  t: () => {},
  setIsLotModalOpen: () => {},
  setIsEditLotModalOpen: () => {},
  setIsMergeLotModalOpen: () => {},
  setIsDivisionLotModalOpen: () => {},
  setIsColumnModalOpen: () => {},
  setDuplicateColumn: () => {},
  selectedRowKeys: [],
  setSelectedRowKeys: () => {},
  isLoading: false,
  setIsFinalVersion: () => {},
  setCollectiveOwnership: () => {},
  isVersionButton: false,
  setIsLabelModalOpen: () => {},
  form: {
    validateFields: () => {}
  },
  dataSource: [],
  setEditTable: () => {},
  editTable: false,
  handleSaveAllTableButtonRef: { current: null },
  handleEditAllTableButtonRef: { current: null },
  setSelectedRowKey: () => {}
};
